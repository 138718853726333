.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #e6d500;
  color: #000000;
}
::selection {
  background: #e6d500;
  color: #000000;
}
* {
  -webkit-tap-highlight-color: rgba(255, 237, 0, 0.1);
  box-sizing: border-box;
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.9375rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
  font-size: 0.9375rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #000000;
  font-family: "Neue Helvetica", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 300;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.2;
  margin: 0 auto;
  max-width: 160rem;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.9375rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1.1875rem;
  }
}
@media (min-width: 47.5rem) {
  body {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.like_h1 {
  letter-spacing: calc((1.375rem + 1.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
  font-size: 1.375rem;
  color: #000000;
  font-family: "Neue Helvetica", sans-serif;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 0.6em;
  text-transform: uppercase;
}
@media (min-width: 20rem) {
  h1,
  .like_h1 {
    font-size: calc(1.375rem + 1.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .like_h1 {
    font-size: 3rem;
  }
}
.no_bold_h1 h1,
.no_bold_h1 .like_h1 {
  font-weight: 300;
}
.blog_overview_page h1,
.blog_overview_page .like_h1 {
  text-align: center;
  margin-top: 0;
  text-transform: none;
  color: #ffed00;
  margin-bottom: 0;
}
.contact_site h1,
.contact_site .like_h1 {
  letter-spacing: calc((1.25rem + 1.375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
  font-size: 1.25rem;
  color: #ffed00;
  border: 0.0625rem solid #fff;
  display: inline-block;
  font-family: "Neue Helvetica", sans-serif;
  font-weight: 200;
  line-height: 1;
  margin: 0;
  padding: 0.6em 1.2em;
  text-align: center;
}
@media (min-width: 20rem) {
  .contact_site h1,
  .contact_site .like_h1 {
    font-size: calc(1.25rem + 1.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .contact_site h1,
  .contact_site .like_h1 {
    font-size: 2.625rem;
  }
}
.wavebionix h1,
.wavebionix .like_h1 {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  text-align: center;
}
.wavebionix h1 .title_addition2,
.wavebionix .like_h1 .title_addition2 {
  text-transform: none;
}
h2,
.like_h2 {
  letter-spacing: calc((1.25rem + 1.75
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
  font-size: 1.25rem;
  color: #000000;
  font-family: "Neue Helvetica", sans-serif;
  font-weight: 200;
  line-height: 1;
  margin: 0 0 0.6em;
  padding-top: 0;
}
@media (min-width: 20rem) {
  h2,
  .like_h2 {
    font-size: calc(1.25rem + 1.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2,
  .like_h2 {
    font-size: 3rem;
  }
}
.black_bg_headline h2,
.black_bg_headline .like_h2 {
  background: #000000;
  color: #ffed00;
  display: inline;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 0.125rem 2.8125rem;
}
h3,
.styled_like_h3 {
  letter-spacing: calc((1rem + 0.1875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
  font-size: 1rem;
  color: #000000;
  font-family: "Neue Helvetica", sans-serif;
  font-weight: 600;
  line-height: 1;
  margin: 0 0 0.6em;
  padding-top: 0.6em;
}
@media (min-width: 20rem) {
  h3,
  .styled_like_h3 {
    font-size: calc(1rem + 0.1875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .styled_like_h3 {
    font-size: 1.1875rem;
  }
}
h4 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
  font-size: 0.875rem;
  color: #000000;
  font-family: "Neue Helvetica", sans-serif;
  font-weight: 600;
  line-height: 1;
  margin: 0 0 0.6em;
}
@media (min-width: 20rem) {
  h4 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4 {
    font-size: 1rem;
  }
}
h5 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
  font-size: 0.875rem;
  color: #000000;
  font-family: "Neue Helvetica", sans-serif;
  font-weight: 600;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5 {
    font-size: 1rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.6em;
}
.like_h2 {
  text-transform: none;
}
a {
  color: #ffed00;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
.link_ul a {
  color: #000000;
}
a:active,
a.active {
  color: #000000;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(255, 237, 0, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
  display: none;
}
a.touch_link {
  color: #000000;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #ffed00;
  display: inline-block;
  margin-bottom: 0.3em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #000000;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(255, 237, 0, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 600;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.6em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 75%;
  font-weight: 200;
  line-height: 1;
  position: relative;
  top: 0;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
@media (max-width: 47.4375rem) {
  .table_overflow {
    overflow-x: auto;
  }
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.2em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.2em;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
  min-width: 43.75rem;
}
@media (max-width: 47.4375rem) {
  table {
    table-layout: fixed;
  }
}
.technische_daten_section table {
  min-width: inherit;
}
td,
th {
  padding: 0.4em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
th {
  background: #000000;
  color: #ffed00;
  font-weight: 600;
  text-align: left;
  border-bottom: 0.3125rem solid #fff;
  border-right: 0.3125rem solid #fff;
}
.technische_daten_section th {
  border: 0;
}
th:first-child {
  background: none;
}
.full_table th:first-child {
  background: #000000;
}
@media (min-width: 47.5rem) {
  .full_table th:first-child {
    position: relative;
    background-clip: padding-box;
  }
  .full_table th:first-child::before {
    background: #000000;
    bottom: 0;
    content: "";
    left: -100%;
    position: absolute;
    right: 100%;
    top: 0;
    z-index: 2;
  }
}
@media (min-width: 47.5rem) {
  .three_td_table th {
    width: 33.33333%;
  }
  .four_td_table th {
    width: 25%;
  }
  th:last-child {
    position: relative;
    border-right: 0;
    background-clip: padding-box;
  }
  th:last-child::before {
    background: #000000;
    bottom: 0;
    content: "";
    right: -100%;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 2;
  }
}
td {
  background: #f2f2f2;
  border-right: 0.3125rem solid #fff;
}
.technische_daten_section td {
  border: 0;
}
.technische_daten_section td:first-child {
  font-weight: 600;
}
@media (min-width: 47.5rem) {
  .three_td_table td {
    width: 33.33333%;
  }
  .four_td_table td {
    width: 25%;
  }
  td:first-child {
    position: relative;
    background-clip: padding-box;
  }
  td:first-child::before {
    background: #f2f2f2;
    bottom: 0;
    content: "";
    left: -100%;
    position: absolute;
    right: 100%;
    top: 0;
    z-index: -1;
  }
  td:last-child {
    position: relative;
    border-right: 0;
    background-clip: padding-box;
  }
  td:last-child::before {
    background: #f2f2f2;
    bottom: 0;
    content: "";
    right: -100%;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: -1;
  }
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.6em;
  padding: 0;
}
ol > li {
  padding-left: 1.2em;
  position: relative;
}
ol > li:before {
  color: #ffed00;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 600;
  left: 0;
  line-height: 1.2;
  position: absolute;
  text-align: right;
  width: 0.9em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_circle.svg") !important;
  margin: 0 0 0.6em;
  padding-left: 1.188em;
}
ul > li {
  margin-bottom: 0.5rem;
  padding-left: 0.3em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list_entry dl {
  border-bottom: 0.0625rem solid #d9d9d9;
  margin: 0.6em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list_entry dt {
  border-top: 0.0625rem solid #d9d9d9;
  font-weight: 600;
  padding: 0.6em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.6em 0.6em 0.6em 0;
  }
}
.defined_list_entry dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dd {
    border-top: 0.0625rem solid #d9d9d9;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.6em 0.4em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none!important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 0;
}
figure.little_picture {
  max-width: 21.875rem !important;
}
figure.bogen_image {
  max-width: 53.125rem !important;
  margin-left: calc(-50vw + 50%);
}
@media (min-width: 160rem) {
  figure.bogen_image {
    margin-left: -11%;
  }
}
figure.certification {
  max-width: 21.875rem !important;
}
@media (max-width: 61.8125rem) {
  figure.certification {
    margin-top: 2.4em;
  }
}
@media (max-width: 47.4375rem) {
  figure.desktop_image {
    display: none;
  }
}
@media (min-width: 47.5rem) {
  figure.mobile_image {
    display: none;
  }
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.2em;
}
figure.floatright {
  float: right;
  margin-left: 1.2em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.3em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 300;
}
.black .form_title_wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
}
.black .form_title_wrapper h2 {
  letter-spacing: calc((1.25rem + 1.75
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
  font-size: 1.25rem;
  border: 0.0625rem solid #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  font-family: "Neue Helvetica", sans-serif;
  font-weight: 200;
  -webkit-box-pack: center;
          justify-content: center;
  line-height: 1;
  margin: 0;
  padding: 0.6em 1.2em;
  text-align: center;
}
@media (min-width: 20rem) {
  .black .form_title_wrapper h2 {
    font-size: calc(1.25rem + 1.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .black .form_title_wrapper h2 {
    font-size: 3rem;
  }
}
.black form a {
  color: #fff;
}
.black form .button_wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  margin-top: 2.8125rem;
}
.black form .button_wrapper .button {
  float: none;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000000;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #ffed00 inset, 0 0 0 0.1875rem rgba(255, 237, 0, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #404040;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #404040;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #404040;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #ffed00;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #ffed00;
  color: #000000;
  display: none;
  font-weight: 600;
  margin-right: 0.3em;
  padding: 0 0.3em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
  font-size: 1.1875rem;
  color: #ffed00;
  font-family: "Neue Helvetica", sans-serif;
  font-weight: 300;
  line-height: 1;
  margin: 0 0 0.6em;
  padding: 0.6em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.black .legend {
  letter-spacing: calc((1.25rem + 1.75
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
  font-size: 1.25rem;
  color: #ffed00;
  font-family: "Neue Helvetica", sans-serif;
  font-weight: 200;
  line-height: 1;
  margin: 0 0 0.6em;
  padding-top: 0;
}
@media (min-width: 20rem) {
  .black .legend {
    font-size: calc(1.25rem + 1.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .black .legend {
    font-size: 3rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.3em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 0.125rem;
  box-shadow: 0 0 0 #d9d9d9 inset;
  color: #000000;
  margin: 0 0 0.8em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #ffed00;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #ffed00;
  box-shadow: 0 0 0.1875rem #ffed00 inset, 0 0 0 0.1875rem rgba(255, 237, 0, 0.2);
  color: #000000;
}
.input:focus,
.textarea:focus {
  border-color: #ffed00;
  box-shadow: 0 0 0.1875rem #ffed00 inset, 0 0 0 0.1875rem rgba(255, 237, 0, 0.2);
  color: #000000;
}
.input {
  height: 2.25rem;
  padding: 0 0.6em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.2;
  overflow: hidden;
  padding: 0.6em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.3em 0.6em 0.6em 0;
  position: relative;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #ffed00;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #ffed00;
  box-shadow: 0 0 0.1875rem #ffed00 inset, 0 0 0 0.1875rem rgba(255, 237, 0, 0.2);
  color: #000000;
}
.control input:focus ~ .indicator {
  border-color: #ffed00;
  box-shadow: 0 0 0.1875rem #ffed00 inset, 0 0 0 0.1875rem rgba(255, 237, 0, 0.2);
  color: #000000;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.6em;
}
.control .indicator {
  -webkit-box-align: center;
          align-items: center;
  background: #fff;
  border-radius: 0.125rem;
  border: 0.0625rem solid #d9d9d9;
  display: -webkit-box;
  display: flex;
  height: 1.375rem;
  -webkit-box-pack: center;
          justify-content: center;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  bottom: 0;
  fill: #000000;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 0.125rem;
  box-shadow: 0 0 0.1875rem #d9d9d9 inset;
  color: #000000;
  cursor: pointer;
  display: block;
  margin: 0 0 0.8em;
  padding: 0 0.6em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #000000;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.6em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #ffed00;
}
.select.error.focus {
  border-color: #ffed00;
  box-shadow: 0 0 0.1875rem #ffed00 inset, 0 0 0 0.1875rem rgba(255, 237, 0, 0.2);
  color: #000000;
}
.select.focus {
  border-color: #ffed00;
  box-shadow: 0 0 0.1875rem #ffed00 inset, 0 0 0 0.1875rem rgba(255, 237, 0, 0.2);
  color: #000000;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #000000;
  cursor: pointer;
  height: calc(2.25rem - (0.0625rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}
.select select:focus::-ms-value {
  background: none;
  color: #000000;
}
@-webkit-keyframes pulsate {
  0%,
  50%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20%,
  70% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@keyframes pulsate {
  0%,
  50%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20%,
  70% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(255, 237, 0, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(255, 237, 0, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(255, 237, 0, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(255, 237, 0, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes kpt_slider_fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes kpt_slider_fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  * + [role="tablist"] {
    text-align: center;
  }
  * + [role="tablist"] a {
    color: #000000;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    font-size: 100%;
    margin: 0 0 0.3em;
    min-width: 100%;
    padding: 0.4em 0;
  }
  [role="tab"] .title {
    letter-spacing: calc((1rem + 0.875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1rem;
    display: block;
    flex-basis: 100%;
    font-weight: 500;
    margin-right: 0.6em;
    overflow: hidden;
    padding: 0.3125rem;
    position: relative;
    text-overflow: ellipsis;
    z-index: 0;
  }
  [role="tab"] .title::after {
    width: 6.25rem;
    background: #000000;
    bottom: 0;
    content: "";
    height: 0.125rem;
    left: 50%;
    opacity: 1;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 2;
  }
  [role="tab"] .icon {
    -webkit-box-align: center;
            align-items: center;
    background: #ffed00;
    display: -webkit-box;
    display: flex;
    fill: #000000;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0 auto;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    width: 4.125rem;
  }
  [role="tab"] .icon svg {
    height: 1.875rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.875rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #ffed00;
  }
  [role="tab"][aria-expanded="true"] .title {
    background: #000000;
    color: #ffed00;
  }
  [role="tab"][aria-expanded="true"] .icon {
    background: #000000;
    fill: #ffed00;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  [role="tab"][aria-expanded="true"] .icon svg {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #ffed00;
    outline: 0;
  }
  [role="tab"]:focus .title {
    background: #000000;
    color: #ffed00;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.2em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper p {
    margin-right: 1.8em;
  }
  .contact_wrapper a {
    color: #000000;
    text-decoration: none;
  }
  .contact_wrapper a:hover {
    color: #808080;
  }
  .contact_wrapper .contact_label {
    width: 5rem;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .address_vcard_button {
    margin: 0.6em 0;
  }
  .address_call_button {
    margin: 0.6em 0;
  }
  .google_maps_link {
    color: #000000;
    font-weight: 300;
  }
  .aqi-animation-header {
    margin-bottom: 2.4em;
  }
  .aqi-animation-header h2:first-child {
    color: #e6d500;
  }
  .aqi-animation-header .aqi_icon {
    display: -webkit-box;
    display: flex;
  }
  .aqi-animation-header .aqi_icon .icon {
    display: -webkit-box;
    display: flex;
    height: 6.25rem;
    width: 18.75rem;
  }
  .aqi-externaldata-content img {
    margin-right: 1.7em;
    width: 637px;
    outline: 3px solid #000000;
    outline-offset: -1px;
  }
  .aqi-externaldata-content .aqi-externaldata-text {
    text-align: right;
    font-size: 100%;
  }
  .aqi-externaldata-content .aqi-externaldata-text strong {
    display: block;
    font-size: 600%;
    font-weight: 600;
    line-height: 1.0em;
  }
  .aqi-externaldata-content .aqi-externaldata-text .aqi-externaldata-text-description {
    margin-top: 0.6em;
  }
  .aqi-externaldata-content .aqi-externaldata-text .aqi-externaldata-text-description strong {
    font-size: 200%;
  }
  .anchor_headline {
    margin: 1.875rem 0;
    padding: 2.8125rem 0;
  }
  .anchor_headline.animated .icon {
    margin-right: 0.9375rem;
    opacity: 1;
    -webkit-transition: opacity 900ms, margin-right 900ms;
    transition: opacity 900ms, margin-right 900ms;
  }
  .anchor_headline .title_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .anchor_headline .title {
    letter-spacing: calc((1.125rem + 0.75
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 1.125rem;
    color: #fff;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
  }
  .anchor_headline .icon {
    fill: #ffed00;
    height: 1.25rem;
    margin-right: 0;
    opacity: 0;
    -webkit-transition: opacity 900ms, margin-right 900ms;
    transition: opacity 900ms, margin-right 900ms;
    width: 1.25rem;
  }
  .top_link {
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: flex;
    color: #000000;
    text-transform: uppercase;
    text-decoration: none;
    margin: 1.2em 0;
  }
  .top_link svg {
    fill: #ffed00;
    height: 3.75rem;
    padding-right: 0.625rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 3.125rem;
  }
  .top_link:hover svg,
  .top_link:focus svg {
    -webkit-transform: translateX(-0.3125rem);
            transform: translateX(-0.3125rem);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .atikon_logo {
    height: 100%;
  }
  .background_image {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .background_image.game_changer_image {
    min-height: 12.5rem;
    position: relative;
    z-index: 10;
  }
  .background_image.game_changer_image.margin_bottom_small {
    margin-bottom: 1.25rem;
  }
  .background_image_overview {
    margin: 0 calc(-100vw + 100%);
    position: relative;
    -webkit-transition: margin-top 300ms ease;
    transition: margin-top 300ms ease;
    padding-bottom: 56.25%;
  }
  .anwendungen_schweissen_styles .background_image_overview {
    min-height: auto !important;
    padding-bottom: 56.25%;
  }
  .background_image_overview.headline_placement .slider_headline {
    top: auto;
    bottom: 10%;
  }
  .background_image_overview .slider_headline {
    position: absolute;
    z-index: 1000;
    top: 10%;
  }
  .background_image_overview .slider_headline .slogan_headline {
    margin-bottom: 1.2em;
  }
  .background_image_overview .slider_headline p,
  .background_image_overview .slider_headline h2,
  .background_image_overview .slider_headline h3,
  .background_image_overview .slider_headline h4 {
    background: #ffed00;
    color: #000000;
    line-height: 1.3;
    display: inline;
    margin: 0;
    letter-spacing: calc((0.875rem + 2.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.875rem;
    font-family: "Neue Helvetica", sans-serif;
    padding-top: 0;
  }
  .background_image_overview .background_image_wrapper {
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 600ms ease-in-out;
    transition: opacity 600ms ease-in-out;
    width: 100%;
    z-index: 1;
  }
  .background_image_overview .background_image_wrapper .background_image {
    background-position: bottom !important;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .background_image_overview .background_image_wrapper.show {
    opacity: 1;
    pointer-events: auto;
  }
  .background_image_overview .background_image_wrapper.show .background_image {
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: header_image;
            animation-name: header_image;
  }
  .background_image_overview .navigation_wrapper {
    bottom: 0.625rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 50%;
    margin: 0 auto;
    max-width: 137.5rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    z-index: 3;
  }
  .background_image_overview .navigation_wrapper .navigation_inner {
    display: -webkit-box;
    display: flex;
    margin-bottom: 0.3125rem;
  }
  .background_image_overview .navigation_wrapper .navigation {
    height: 3rem;
    width: 3rem;
    -webkit-box-align: center;
            align-items: center;
    background: #ffed00;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin-left: 0.3125rem;
    padding: 0;
    -webkit-transition: background 300ms, opacity 300ms, visibility 300ms;
    transition: background 300ms, opacity 300ms, visibility 300ms;
    z-index: 15;
  }
  .background_image_overview .navigation_wrapper .navigation .icon {
    fill: #ffed00;
    height: 1.8125rem;
    width: 1.8125rem;
  }
  .background_image_overview .navigation_wrapper .navigation:hover,
  .background_image_overview .navigation_wrapper .navigation:focus {
    background-color: #ffed00;
  }
  .background_image_overview .navigation_wrapper .navigation.prev {
    margin-left: 0;
  }
  .background_image_overview .navigation_wrapper .navigation.prev .icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .background_image_overview .navigation_wrapper .pagination {
    display: -webkit-box;
    display: flex;
  }
  .background_image_overview .navigation_wrapper .pagination .item {
    background-color: #fff;
    border-radius: 100%;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    height: 0.625rem;
    margin-right: 0.3125rem;
    width: 0.625rem;
  }
  .background_image_overview .navigation_wrapper .pagination .item:last-child {
    margin-right: 0;
  }
  .background_image_overview .navigation_wrapper .pagination .item.active {
    background-color: #ffed00;
    opacity: 1;
  }
  .background_image_overview .navigation_wrapper > div {
    margin-right: 1.2em;
  }
  .background_image_overview .navigation_wrapper > div:last-child {
    margin-right: 0;
  }
  .infobanner {
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 55%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 70%;
    z-index: 5000;
  }
  .infobanner .popup_infobanner {
    opacity: 0;
    visibility: hidden;
  }
  .infobanner .popup_infobanner.show {
    opacity: 1;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    visibility: visible;
  }
  .infobanner .popup_infobanner .infobanner_element {
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .infobanner .popup_infobanner .infobanner_element.animated {
    -webkit-animation: fade_in_pictures;
            animation: fade_in_pictures;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transition: opacity 900ms ease-out;
    transition: opacity 900ms ease-out;
  }
  .infobanner .popup_infobanner .infobanner_element.active {
    opacity: 1;
    position: relative;
  }
  .infobanner .popup_infobanner .infobanner_element.active img {
    margin: 0 auto;
  }
  .infobanner .popup_infobanner .infobanner_element img {
    display: block;
    width: 100%;
  }
  .infobanner .popup_infobanner .infobanner_element video {
    display: block;
    width: 100%;
  }
  .infobanner .popup_infobanner .close {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    fill: #ffed00;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: absolute;
    right: 0.6em;
    top: 0.6em;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    width: 2.75rem;
    z-index: 300;
  }
  .infobanner .popup_infobanner .close:focus,
  .infobanner .popup_infobanner .close:hover {
    fill: #fff;
    -webkit-transform: scale(1.1) rotate(90deg);
            transform: scale(1.1) rotate(90deg);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .blog_text_headline_wrapper {
    position: relative;
    color: #fff;
    text-align: center;
    padding: 0 0 2.4em;
  }
  .blog_text_headline_wrapper:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    right: -100%;
    background: #000000;
    z-index: -1;
  }
  .blog_text_headline_wrapper .blog_text_headline {
    margin: 0 auto;
  }
  .blog_text_headline_wrapper .blog_text_headline strong {
    display: block;
    margin-bottom: 1.2em;
  }
  .blog_container {
    margin: 0 auto;
    max-width: 137.5rem;
    min-width: 20rem;
    padding: 0;
  }
  .blog_article_overview.big_title .blog_entry .title_wrapper p {
    letter-spacing: calc((1rem + 2
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 1rem;
  }
  .blog_article_overview .overview_title {
    background: #000000;
    color: #ffed00;
    padding: 0.6em 1.2em;
    margin-bottom: 1.2em;
    position: relative;
  }
  .blog_article_overview .overview_title::after {
    content: "";
    position: absolute;
    left: 100%;
    right: -100%;
    top: 0;
    bottom: 0;
    background: #000000;
  }
  .blog_article_overview .blog_entry {
    display: block;
    text-decoration: none;
    margin-bottom: 1.2em;
  }
  .blog_article_overview .blog_entry:hover .background::after,
  .blog_article_overview .blog_entry:focus .background::after {
    opacity: 0;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .blog_article_overview .blog_entry:hover .title_wrapper p,
  .blog_article_overview .blog_entry:focus .title_wrapper p {
    background: #ffed00;
    color: #000000;
  }
  .blog_article_overview .blog_entry:hover .title_wrapper .sub_title p,
  .blog_article_overview .blog_entry:focus .title_wrapper .sub_title p {
    background: #000000;
    color: #fff;
  }
  .blog_article_overview .blog_entry .image_wrapper {
    position: relative;
  }
  .blog_article_overview .blog_entry .background {
    height: 0;
    padding-bottom: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 20;
    position: relative;
  }
  .blog_article_overview .blog_entry .background::after {
    background: rgba(0, 0, 0, 0.35);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .blog_article_overview .blog_entry .title_wrapper {
    position: absolute;
    top: auto;
    left: 0.6em;
    right: 0;
    bottom: 0.6em;
    z-index: 100;
    color: #000000;
  }
  .blog_article_overview .blog_entry .title_wrapper .sub_title {
    margin-top: 1.2em;
  }
  .blog_article_overview .blog_entry .title_wrapper p {
    color: #fff;
    line-height: 1.3;
    display: inline;
    margin: 0;
    letter-spacing: calc((1rem + 0.875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 1rem;
    font-family: "Neue Helvetica", sans-serif;
  }
  .blog_article_overview .blog_entry .info {
    background: #dadada;
    padding: 0.6em;
    color: #000000;
    text-decoration: none;
  }
  .blog_article_overview .blog_entry .info .date_wrapper {
    border-bottom: 0.0625rem solid #000000;
    margin-bottom: 0.6em;
    padding-bottom: 0.6em;
    display: -webkit-box;
    display: flex;
  }
  .blog_article_overview .blog_entry .info .date_wrapper time {
    position: relative;
    padding-right: 1.2em;
    margin-right: 1.2em;
  }
  .blog_article_overview .blog_entry .info .date_wrapper time::after {
    color: #000000;
    content: "|";
    left: 100%;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  .blog_article_overview .blog_entry .info .date_wrapper .lesedauer {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
  }
  .blog_article_overview .blog_entry .info .date_wrapper .lesedauer .icon {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    border: 0.0625rem solid #000000;
    border-radius: 100%;
    height: 1.125rem;
    width: 1.125rem;
    fill: #000000;
    margin-right: 0.3125rem;
  }
  .blog_icon_text:hover .image_wrapper .image_hover {
    opacity: 1;
    -webkit-transition: opacity 300ms ease;
    transition: opacity 300ms ease;
  }
  .blog_icon_text .image_wrapper {
    position: relative;
    margin-bottom: -4.8em;
  }
  .blog_icon_text .image_wrapper .image_hover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: opacity 300ms ease;
    transition: opacity 300ms ease;
    z-index: 10;
  }
  .blog_icon_text .grey_content {
    position: relative;
    padding-left: 1.2em;
  }
  .blog_icon_text .grey_content:after {
    background: #dadada;
    bottom: 0;
    top: 0;
    left: 0;
    right: -100%;
    content: "";
    z-index: -1;
    position: absolute;
  }
  .blog_icon_text .content {
    padding-left: 1.2em;
  }
  .backlink_wrapper {
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: flex;
    color: #000000;
    text-transform: uppercase;
    text-decoration: none;
  }
  .backlink_wrapper svg {
    fill: #ffed00;
    height: 3.75rem;
    padding-right: 0.625rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 3.125rem;
  }
  .blog_call_to_action {
    margin: 1.2em 0 2.4em;
  }
  .blog_call_to_action .title {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: start;
            align-items: flex-start;
    margin-bottom: 1.2em;
  }
  .blog_call_to_action .title h2 {
    background: #000000;
    text-transform: uppercase;
    color: #ffed00;
    line-height: 1.3;
    font-weight: 600;
    display: inline;
    letter-spacing: calc((0.875rem + 2.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.875rem;
    font-family: "Neue Helvetica", sans-serif;
    margin: 0 0 0.3125rem 0;
  }
  .blog_call_to_action .cta_link {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    margin-bottom: 1.2em;
    text-decoration: none;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .blog_call_to_action .cta_link:hover,
  .blog_call_to_action .cta_link:focus {
    -webkit-transform: translateX(0.625rem);
            transform: translateX(0.625rem);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .blog_call_to_action .cta_link .icon {
    height: 7.5rem;
    width: 7.5rem;
    fill: #000000;
    display: block;
    margin-right: 1.2em;
  }
  .blog_call_to_action .cta_link .text span {
    background: #000000;
    text-transform: uppercase;
    color: #fff;
    line-height: 1.3;
    font-weight: 600;
    display: inline;
    margin: 0 0 0.4em 0;
    letter-spacing: calc((0.875rem + 2.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.875rem;
    font-family: "Neue Helvetica", sans-serif;
  }
  .blog_slider {
    position: relative;
  }
  .blog_slider:focus {
    outline: 0;
  }
  .blog_slider .slides_wrapper {
    overflow: hidden;
  }
  .blog_slider .slides_wrapper .slides {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
  }
  .blog_slider .slides_wrapper .slides .slide {
    opacity: 0;
    position: relative;
    visibility: hidden;
    width: 100%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="-100"] {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="-200"] {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="-300"] {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="-400"] {
    -webkit-transform: translateX(-400%);
            transform: translateX(-400%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="-500"] {
    -webkit-transform: translateX(-500%);
            transform: translateX(-500%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="-600"] {
    -webkit-transform: translateX(-600%);
            transform: translateX(-600%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="-700"] {
    -webkit-transform: translateX(-700%);
            transform: translateX(-700%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="-800"] {
    -webkit-transform: translateX(-800%);
            transform: translateX(-800%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="-900"] {
    -webkit-transform: translateX(-900%);
            transform: translateX(-900%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="-1000"] {
    -webkit-transform: translateX(-1000%);
            transform: translateX(-1000%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="000"] {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="100"] {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="200"] {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="300"] {
    -webkit-transform: translateX(300%);
            transform: translateX(300%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="400"] {
    -webkit-transform: translateX(400%);
            transform: translateX(400%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="500"] {
    -webkit-transform: translateX(500%);
            transform: translateX(500%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="600"] {
    -webkit-transform: translateX(600%);
            transform: translateX(600%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="700"] {
    -webkit-transform: translateX(700%);
            transform: translateX(700%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="800"] {
    -webkit-transform: translateX(800%);
            transform: translateX(800%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="900"] {
    -webkit-transform: translateX(900%);
            transform: translateX(900%);
  }
  .blog_slider .slides_wrapper .slides .slide.slide[data-position="1000"] {
    -webkit-transform: translateX(1000%);
            transform: translateX(1000%);
  }
  .blog_slider .slides_wrapper .slides .slide.no_grid {
    min-width: 100%;
  }
  .blog_slider .slides_wrapper .slides .slide.active {
    opacity: 1;
    visibility: visible;
  }
  .blog_slider .slides_wrapper .slides .slide.visible {
    bottom: 0;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    visibility: visible;
  }
  .blog_slider .slides_wrapper .slides .slide:focus {
    outline: 0;
  }
  .blog_slider .slide.animated {
    -webkit-transition: -webkit-transform 300 ease-out;
    transition: -webkit-transform 300 ease-out;
    transition: transform 300 ease-out;
    transition: transform 300 ease-out, -webkit-transform 300 ease-out;
  }
  .blog_slider.fade .slide.animated {
    -webkit-animation: kpt_slider_fade 600 ease-out forwards;
            animation: kpt_slider_fade 600 ease-out forwards;
    opacity: 0;
    -webkit-transition: unset;
    transition: unset;
  }
  .blog_slider.navigation_outside {
    padding: 0 3.125rem;
  }
  .blog_slider .navigation a {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .blog_slider .navigation a .icon {
    display: block;
    fill: #000000;
    height: 2.75rem;
    min-width: 2.75rem;
    -webkit-transition: fill 300 ease-out;
    transition: fill 300 ease-out;
    width: 2.75rem;
  }
  .blog_slider .navigation a.prev {
    left: 0;
  }
  .blog_slider .navigation a.prev .icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .blog_slider .navigation a.next {
    right: 0;
  }
  .blog_slider .navigation a:hover .icon {
    fill: #ffed00;
  }
  .blog_slider.pagination {
    margin-bottom: 2.4em;
  }
  .blog_slider .pagination {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-top: 1.2em;
  }
  .blog_slider .pagination a {
    border-radius: 50%;
    background: #d9d9d9;
    display: inline-block;
    height: 0.75rem;
    margin: 0 0.25rem;
    width: 0.75rem;
  }
  .blog_slider .pagination a.active,
  .blog_slider .pagination a:hover {
    background-color: #000000;
  }
  .breadcrumbs {
    display: none;
  }
  .button {
    border-radius: 0.125rem;
    font-size: 1rem;
    padding: 0 1.2em;
    letter-spacing: calc((0.9375rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    background: #ffed00;
    border: 0;
    clear: left;
    color: #000000;
    display: block;
    float: left;
    -webkit-box-pack: center;
            justify-content: center;
    min-width: 11.875rem;
    position: relative;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.25rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    z-index: 20;
  }
  .button .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 300;
  }
  .button .icon + .text {
    padding-left: 0.4em;
  }
  .button .arrows {
    font-weight: 500;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(255, 237, 0, 0.2);
    outline: 0;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #ffed00 inset;
    letter-spacing: calc((0.9375rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    background: #ffed00;
    border: 0;
    clear: left;
    color: #fff;
    display: block;
    float: left;
    -webkit-box-pack: center;
            justify-content: center;
    min-width: 11.875rem;
    position: relative;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.25rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    z-index: 20;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 300;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.4em;
  }
  .button:active .arrows,
  .button.active .arrows {
    font-weight: 500;
  }
  .backlink {
    margin-top: 2.5rem;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #ffed00;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.3em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #ffed00;
    height: auto;
    left: 0.3em;
    top: 0.3em;
    width: auto;
  }
  .call_to_action {
    position: relative;
  }
  .call_to_action .cta_link {
    font-size: 1.5rem;
    text-decoration: none;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .call_to_action .cta_link .icon_wrapper {
    height: 5rem;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    position: relative;
    width: 100%;
  }
  .call_to_action .cta_link .icon {
    height: 2.5rem;
    width: 2.5rem;
    display: block;
    fill: #ffed00;
  }
  .call_to_action .cta_link .text_wrapper {
    position: relative;
    padding: 1.2em 0;
    display: block;
  }
  .call_to_action .cta_link .text {
    color: #000000;
    line-height: 1.3;
    text-transform: uppercase;
    font-weight: 500;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .call_to_action .cta_link .text span {
    margin-bottom: 0.25rem;
    padding: 0.125rem 0.3125rem;
    display: inline-block;
  }
  .call_to_action .cta_link:hover,
  .call_to_action .cta_link:focus {
    -webkit-transform: translateX(1.25rem);
            transform: translateX(1.25rem);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .call_to_action .cta_link:hover .text,
  .call_to_action .cta_link:focus .text {
    text-decoration: underline;
  }
  .call_to_action .image {
    -webkit-box-align: center;
            align-items: center;
    bottom: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    max-width: 11.25rem;
    position: absolute;
    right: 0;
    z-index: 50;
    margin-bottom: -2.4em;
  }
  .call_to_action .image img {
    width: 100%;
  }
  .certificate .image {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 2.4em;
    position: relative;
    z-index: 10;
    min-height: 0.0625rem;
  }
  .certificate .image img {
    position: relative;
    z-index: 50;
  }
  .certificate .image .bg_image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: -100%;
    position: absolute;
    right: -100%;
    top: 0;
    z-index: 0;
  }
  .certificate .content {
    -webkit-box-flex: 1;
            flex-grow: 1;
    letter-spacing: calc((0.9375rem + 0.9375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
  }
  .certificate .title {
    text-transform: uppercase;
  }
  .edit_in_cms {
    display: none;
  }
  .company_logo {
    max-width: 9.375rem;
    display: block;
    margin: 1.25rem 1.375rem;
    min-width: 7.5rem;
    -webkit-transition: margin 300ms ease;
    transition: margin 300ms ease;
  }
  .company_logo_addition {
    display: block;
    margin-left: 1.375rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .content_slider {
    position: relative;
  }
  .content_slider .slide_wrapper {
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    position: relative;
  }
  .content_slider .slide_wrapper .slide {
    -webkit-box-flex: 1;
            flex: 1 0 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    max-width: 100%;
    -webkit-transition: -webkit-transform 900ms;
    transition: -webkit-transform 900ms;
    transition: transform 900ms;
    transition: transform 900ms, -webkit-transform 900ms;
    z-index: 10;
    display: -webkit-box;
    display: flex;
    overflow: hidden;
  }
  .anwendungen_schweissen_styles .content_slider {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .anwendungen_schweissen_styles .content_slider .navigation_wrapper {
    -webkit-box-ordinal-group: 2;
            order: 1;
    margin-top: 0;
    margin-bottom: 1.2em;
  }
  .anwendungen_schweissen_styles .content_slider .slide_wrapper {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .content_slider.pagination_slider {
    display: block;
  }
  .content_slider.pagination_slider .navigation_wrapper {
    margin-top: 1.2em;
    margin-bottom: 0;
  }
  .content_slider .navigation_wrapper {
    display: -webkit-box;
    display: flex;
    position: relative;
    -webkit-box-pack: center;
            justify-content: center;
    z-index: 30;
    margin-top: 1.2em;
  }
  .content_slider .dot {
    background-color: #c6c6c6;
    border-radius: 50%;
    cursor: pointer;
    height: 0.625rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 0.625rem;
    margin-right: 0.3125rem;
  }
  .content_slider .dot.active_dot {
    background-color: #000000;
  }
  .content_slider.full_pagination .dot {
    background-color: transparent;
    border-radius: 0;
    color: #000000;
    height: auto;
    margin: 0;
    position: relative;
    text-decoration: none;
    width: auto;
  }
  .content_slider.full_pagination .dot.active_dot {
    font-weight: 600;
  }
  .content_slider.full_pagination .dot:after {
    content: attr(title);
    position: relative;
  }
  .content_slider.full_pagination .dot:before {
    content: "< >";
    font-weight: 300;
    margin: 0 0.3125rem;
    position: relative;
  }
  .content_slider.full_pagination .dot:first-child:before {
    display: none;
  }
  .download_overview {
    margin-bottom: 0.6em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link h3.title_wrapper {
    margin-bottom: 0.3em;
    padding-top: 0;
  }
  .download_overview .touch_link h3.title_wrapper .title {
    letter-spacing: calc((0.9375rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    color: #000000;
    font-weight: 600;
    text-decoration: none;
  }
  .download_overview .touch_link:hover .title {
    text-decoration: underline;
  }
  .download_overview .icon {
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.6em;
    width: 1.5rem;
  }
  .download_overview .icon_doc {
    fill: #2a5699;
  }
  .download_overview .icon_xls {
    fill: #4e892d;
  }
  .download_overview .icon_pdf {
    fill: #e44e4e;
  }
  .download_overview .icon_online {
    fill: #0074ce;
  }
  .download_overview_expanded {
    margin-top: 0.9em;
  }
  .download_overview_expanded .expanded_wrapper {
    padding: 3.9375rem 0;
  }
  .download_overview_expanded .expanded_wrapper .title_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .download_overview_expanded .expanded_wrapper .text_wrapper {
    margin-top: 1.875rem;
  }
  .download_overview_expanded .expanded_wrapper svg {
    height: 2rem;
    width: 7.375rem;
  }
  .download_overview_expanded .expanded_wrapper .title {
    font-size: 1.5625rem;
    color: #808080;
    font-weight: 600;
    padding-left: 0.6em;
    text-transform: uppercase;
  }
  .download_overview_expanded .expanded_wrapper .title.yellow_text {
    color: #000000;
    background-color: #ffed00;
    margin-left: 0.6em;
    padding-left: 0;
    padding-right: 0.6em;
  }
  .download_overview_expanded .expanded_wrapper .description {
    font-size: 1.125rem;
  }
  .download_overview_expanded .expanded_wrapper .description.yellow_text {
    color: #000000;
    background-color: #ffed00;
    display: inline;
  }
  .download_overview_expanded .expanded_wrapper .button {
    -webkit-box-align: center;
            align-items: center;
    background: #000000;
    border: 0.0625rem solid #000000;
    color: #ffed00;
    display: -webkit-box;
    display: flex;
    font-weight: 200;
    height: 2.625rem;
    padding: 0 0.3125rem;
    width: 100%;
    font-size: 1.5625rem;
  }
  .download_overview_expanded .expanded_wrapper .button:hover {
    background: #ffed00;
    border: 0.0625rem solid #ffed00;
    color: #000000;
  }
  .download_overview_expanded .expanded_wrapper a {
    display: inline-block;
    text-decoration: none;
  }
  .erfassungssysteme {
    padding-top: 3.75rem;
  }
  .erfassungssysteme .grey_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    padding: 2.8125rem 0;
  }
  .erfassungssysteme .grey_wrapper.no_padding_bottom {
    padding-bottom: 0;
  }
  .erfassungssysteme .grey_wrapper.no_padding_bottom img {
    display: block;
    margin-bottom: 2.4em;
  }
  .erfassungssysteme .black_wrapper {
    padding: 2.8125rem 0;
  }
  .erfassungssysteme .black_wrapper.one_span {
    -webkit-column-count: 1;
            column-count: 1;
    padding: 2.8125rem 0;
  }
  .erfassungssysteme .black_wrapper.one_span .description .white_headline {
    color: #fff;
  }
  footer {
    overflow: hidden;
  }
  footer .footer_wrapper {
    -webkit-box-align: center;
            align-items: center;
    background: #f2f2f2;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 1.25rem 0;
    position: relative;
    font-size: 0.8125rem;
  }
  .no_footer_margin footer .footer_wrapper {
    margin-top: 0 !important;
  }
  footer .footer_wrapper .related_links ul {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  footer .footer_wrapper .related_links li {
    padding: 0.125rem;
  }
  footer .footer_wrapper .related_links a {
    color: #000000;
    text-decoration: none;
  }
  footer .footer_wrapper .related_links a:hover {
    color: #808080;
  }
  .go_to_top {
    bottom: 0.6em;
    opacity: 0;
    padding: 0.625rem;
    position: fixed;
    right: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: -1;
  }
  .go_to_top img {
    height: 50%;
    width: 50%;
  }
  .go_to_top.show {
    bottom: 1.2em;
    opacity: 1;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: 16;
  }
  .form_disclaimer {
    background: #f2f2f2;
    border-radius: 0.125rem;
    color: #000000;
    font-size: 85%;
    margin-bottom: 0.6em;
    padding: 0.8em 1.2em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    height: 2.25rem;
    margin: 0 0 0.8em;
    position: relative;
  }
  .file.error .file_name {
    border-color: #ffed00;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #d9d9d9;
    border-radius: 0.125rem 0 0 0.125rem;
    box-shadow: 0 0 0.1875rem #d9d9d9 inset;
    color: #000000;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    height: 2.25rem;
    line-height: calc(2.25rem - (0.0625rem * 2));
    overflow: hidden;
    padding: 0 0.6em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
  }
  .file .file_button {
    border-radius: 0 0.125rem 0.125rem 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.2em;
    z-index: 10;
    letter-spacing: calc((0.9375rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    background: #ffed00;
    border: 0;
    clear: left;
    color: #000000;
    display: block;
    float: left;
    -webkit-box-pack: center;
            justify-content: center;
    min-width: 11.875rem;
    position: relative;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.25rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    z-index: 20;
  }
  .file .file_button .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 300;
  }
  .file .file_button .icon + .text {
    padding-left: 0.4em;
  }
  .file .file_button .arrows {
    font-weight: 500;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(255, 237, 0, 0.2);
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2.25rem;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
  }
  .file [type="file"]:hover ~ .file_button {
    color: #fff;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #ffed00 inset;
    letter-spacing: calc((0.9375rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    background: #ffed00;
    border: 0;
    clear: left;
    color: #fff;
    display: block;
    float: left;
    -webkit-box-pack: center;
            justify-content: center;
    min-width: 11.875rem;
    position: relative;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.25rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    z-index: 20;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 300;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.4em;
  }
  .file [type="file"]:active ~ .file_button .arrows {
    font-weight: 500;
  }
  .formular .button_wrapper button {
    cursor: pointer;
    height: 2.25rem;
    margin: 0.6em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.2em;
    width: 100%;
  }
  .formular .button_wrapper button::-moz-focus-inner {
    border: 0;
  }
  .google_maps {
    margin: 0 0 0.6em;
  }
  .google_maps .map {
    background: #f2f2f2;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.6em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    color: #000000;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #000000;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(255, 237, 0, 0.2);
    z-index: 15;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.25rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    width: 100%;
    letter-spacing: calc((0.9375rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    background: #fff;
    border: 0;
    clear: left;
    color: #000000;
    display: block;
    float: left;
    -webkit-box-pack: center;
            justify-content: center;
    min-width: 11.875rem;
    position: relative;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.25rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    z-index: 20;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 300;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.4em;
  }
  .google_maps .travel_mode_radio .arrows {
    font-weight: 500;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000000;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0.125rem 0 0 0.125rem;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0.125rem 0.125rem 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0.125rem;
    color: #ffed00;
    font-size: 85%;
    margin-bottom: 0.6em;
    padding: 0.8em 1.2em;
  }
  .google_maps .direction {
    margin: 0.6em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.6em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #d9d9d9;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #d9d9d9;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.6em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.6em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.6em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .container {
    margin: 0 auto;
    max-width: 137.5rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.reverse_xsmall {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  .row.double_column_margin {
    margin-left: -3.28125rem;
    margin-right: -3.28125rem;
  }
  .row.double_column_margin > [class*="span"] {
    padding-left: 3.28125rem;
    padding-right: 3.28125rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  header {
    position: relative;
  }
  .top_header {
    opacity: 1;
    overflow: hidden;
    -webkit-transition: opacity 900ms;
    transition: opacity 900ms;
    z-index: 6000;
  }
  .top_header.hide {
    opacity: 0;
    -webkit-transition: opacity 900ms;
    transition: opacity 900ms;
  }
  .top_header .top_header_wrapper {
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .logo_header {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    z-index: 9;
  }
  .logo_header .logo_wrapper {
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    display: -webkit-box;
    display: flex;
    position: relative;
    -webkit-transition: background 300ms, padding 300ms;
    transition: background 300ms, padding 300ms;
  }
  .logo_header .logo_wrapper:before {
    background: #fff;
    bottom: 0;
    content: "";
    left: -62.5rem;
    position: absolute;
    right: 100%;
    top: 0;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .logo_header .logo_addition_wrapper {
    background: #000000;
    width: 100%;
  }
  .logo_header .logo_addition_wrapper .img_container {
    overflow: visible;
  }
  .menu_wrapper {
    -webkit-box-align: end;
            align-items: flex-end;
    background: #000000;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    padding: 0.3125rem 1rem;
    position: relative;
    -webkit-transition: background 300ms, padding 300ms;
    transition: background 300ms, padding 300ms;
    width: 100%;
  }
  .menu_wrapper:before,
  .menu_wrapper:after {
    background: #000000;
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .menu_wrapper:after {
    left: 100%;
    right: -62.5rem;
  }
  .menu_wrapper .menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .menu_wrapper .test_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .contact_link {
    text-decoration: none;
  }
  .contact_link:hover {
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .contact_link:hover .icon svg {
    fill: #ffed00;
  }
  .contact_link .icon svg {
    fill: #000000;
    height: 2.375rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.375rem;
  }
  .contact_link .text {
    text-transform: uppercase;
    color: #000000;
  }
  .onlineshop_link {
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
  }
  .onlineshop_link:hover {
    -webkit-transform: rotate(7deg) scale(1.1);
            transform: rotate(7deg) scale(1.1);
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
  }
  .onlineshop_link .icon svg {
    height: 2.375rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.375rem;
  }
  .bg_outer_wrapper {
    overflow: hidden;
  }
  .bg_outer_wrapper .sitetitle_wrapper .sitetitle {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .bg_outer_wrapper .header_content_wrapper {
    bottom: 0;
    position: absolute;
    text-shadow: 0.1875rem 0.125rem 0.1875rem #000000;
  }
  .bg_outer_wrapper .header_content_wrapper p,
  .bg_outer_wrapper .header_content_wrapper h1 {
    color: #fff;
    font-weight: 200;
    font-size: 1.375rem;
  }
  .bg_outer_wrapper .header_content_wrapper .arrow_down {
    -webkit-animation: pulsate 3000ms infinite;
            animation: pulsate 3000ms infinite;
    height: 1.5625rem;
    width: 1.5625rem;
  }
  .bg_outer_wrapper .header_content_wrapper .arrow_down .icon {
    fill: #ffed00;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .bg_outer_wrapper .header_content_wrapper .arrow_down .icon:hover {
    fill: #000000;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .bg_outer_wrapper .header_content_wrapper .arrow_down .icon svg {
    height: 1.5625rem;
    width: 1.5625rem;
  }
  .bg_wrapper {
    height: 70vh;
    overflow: hidden;
    position: relative;
  }
  .image_content {
    -webkit-box-align: center;
            align-items: center;
    bottom: 1.25rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    padding: 0 1.25rem;
    position: absolute;
    right: 0;
    text-align: center;
  }
  .image_content h1 {
    color: #fff;
  }
  .image_content .arrow_down {
    -webkit-animation: pulsate 3000ms infinite;
            animation: pulsate 3000ms infinite;
  }
  .image_content .arrow_down .icon {
    fill: #ffed00;
    height: 1.5625rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 1.5625rem;
  }
  .image_content .arrow_down .icon:hover {
    fill: #000000;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .image_content .arrow_down .icon svg {
    height: 1.5625rem;
    width: 1.5625rem;
  }
  .internship .image_content.jobs_and_career_sitetitle,
  .bachelor .image_content.jobs_and_career_sitetitle,
  .apprenticeship .image_content.jobs_and_career_sitetitle {
    bottom: 30%;
    margin-top: 0 !important;
  }
  .job_process .image_content.jobs_and_career_sitetitle {
    bottom: auto;
    margin-top: 0 !important;
    top: 10%;
  }
  .trainee .image_content.jobs_and_career_sitetitle {
    bottom: 10%;
  }
  .sitetitle {
    text-shadow: 0.1875rem 0.125rem 0.625rem #000000;
  }
  .header_background {
    background-color: black;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
  }
  .blog_overview_page .header_background {
    background-position: bottom;
  }
  .header_headline_blog {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 200;
  }
  .header_headline_blog .slogan_headline {
    margin-bottom: 1.2em;
  }
  .header_headline_blog .subheadline_blog {
    margin-top: 1.2em;
  }
  .header_headline_blog p {
    background: #ffed00;
    color: #000000;
    line-height: 1.3;
    display: inline;
    margin: 0;
    letter-spacing: calc((0.875rem + 2.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.875rem;
    font-family: "Neue Helvetica", sans-serif;
  }
  .header_slider {
    position: relative;
    z-index: 100;
    height: 100%;
  }
  .header_slider .slide {
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }
  .header_slider .slide img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .header_slider .slide .slider_headline {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 200;
  }
  .header_slider .slide .slider_headline .slogan_headline {
    margin-bottom: 1.2em;
  }
  .header_slider .slide .slider_headline p {
    background: #ffed00;
    color: #000000;
    line-height: 1.3;
    display: inline;
    margin: 0;
    letter-spacing: calc((0.875rem + 2.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.875rem;
    font-family: "Neue Helvetica", sans-serif;
  }
  .header_slider .slide.animated {
    -webkit-transition: opacity 900ms ease-out;
    transition: opacity 900ms ease-out;
  }
  .header_slider .slide.active {
    opacity: 1;
  }
  .header_slider .slide.active img {
    display: block;
    margin: 0 auto;
  }
  .header_slider .slider_navigation {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1000;
  }
  .header_slider .slider_navigation .icon {
    height: 44px;
    width: 44px;
  }
  .header_slider .slider_navigation .icon svg {
    fill: #000000;
    height: 44px;
    width: 44px;
  }
  .header_slider .slider_navigation .prev_slide svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .header_slider .pagination {
    position: absolute;
    bottom: 1.2em;
    z-index: 200;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: -webkit-box;
    display: flex;
  }
  .header_slider .pagination a {
    height: 0.625rem;
    width: 0.625rem;
    background-color: #c6c6c6;
    margin-right: 0.3125rem;
    border-radius: 100%;
    border: 0.0625rem solid #c6c6c6;
  }
  .header_slider .pagination a.active,
  .header_slider .pagination a:hover {
    background: #000000;
  }
  .image_gallery_overview {
    position: relative;
  }
  .image_gallery_entry {
    background-color: #f2f2f2;
    display: block;
    position: relative;
  }
  .image_gallery_entry .title {
    letter-spacing: calc((0.9375rem + 0.9375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    bottom: 0.625rem;
    color: #fff;
    font-weight: 500;
    left: 0.625rem;
    position: absolute;
    right: 0.625rem;
    text-shadow: -0.0625rem 0 #000000, 0 0.0625rem #000000, 0.0625rem 0 #000000, 0 -0.0625rem #000000;
    z-index: 10;
  }
  .image_gallery_entry .title_addition {
    font-size: 85%;
  }
  .image_gallery_entry:focus,
  .image_gallery_entry:hover {
    outline: 0;
  }
  .image_gallery_entry:focus .open,
  .image_gallery_entry:hover .open {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_entry:focus .open svg,
  .image_gallery_entry:hover .open svg {
    -webkit-animation: news_photo 900ms;
            animation: news_photo 900ms;
  }
  .image_gallery_entry .open {
    background: rgba(0, 0, 0, 0.92);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
    visibility: hidden;
  }
  .image_gallery_entry .open svg {
    height: 3.75rem;
    margin: auto;
    width: 3.75rem;
  }
  .image_gallery_entry .image {
    background-position: 50%;
    background-size: cover;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
  }
  .image_gallery_overlay {
    background: rgba(0, 0, 0, 0.92);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
  }
  .image_gallery_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .gallery_slider {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    white-space: nowrap;
    width: 100%;
  }
  .image_gallery_overlay .gallery_slider.bounce_from_right {
    -webkit-animation: image_gallery_bounce_from_right 300ms ease-out;
            animation: image_gallery_bounce_from_right 300ms ease-out;
  }
  .image_gallery_overlay .gallery_slider.bounce_from_left {
    -webkit-animation: image_gallery_bounce_from_left 300ms ease-out;
            animation: image_gallery_bounce_from_left 300ms ease-out;
  }
  .image_gallery_overlay .full_image {
    display: inline-block;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .image_gallery_overlay .full_image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 0;
  }
  .image_gallery_overlay .full_image figure {
    display: inline-block;
    height: 100%;
    margin: 0;
    position: relative;
  }
  .image_gallery_overlay .full_image figcaption {
    letter-spacing: calc((1rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1rem;
    background: none;
    color: #fff;
    display: block;
    font-weight: 300;
    left: 0;
    padding: 0.6em;
    position: absolute;
    white-space: normal;
    z-index: 10;
  }
  .image_gallery_overlay .full_image img {
    display: inline-block;
    height: auto;
    max-height: 70%;
    max-width: 100%;
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    vertical-align: middle;
    visibility: hidden;
    width: auto;
  }
  .image_gallery_overlay .full_image img.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .spinner {
    display: inline-block;
    height: 1.125rem;
    left: 50%;
    margin-left: -2.1875rem;
    margin-top: -0.5625rem;
    position: absolute;
    top: 50%;
    width: 4.375rem;
  }
  .image_gallery_overlay .spinner .bounce {
    -webkit-animation: image_gallery_spinner 900ms infinite;
            animation: image_gallery_spinner 900ms infinite;
    background: #ffed00;
    border-radius: 100%;
    display: inline-block;
    height: 1.125rem;
    margin: 0 0.25rem;
    width: 1.125rem;
  }
  .image_gallery_overlay .spinner .bounce_1 {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
  }
  .image_gallery_overlay .spinner .bounce_2 {
    -webkit-animation-delay: -150ms;
            animation-delay: -150ms;
  }
  .image_gallery_overlay nav a {
    cursor: pointer;
    display: block;
    position: absolute;
  }
  .image_gallery_overlay nav .previous,
  .image_gallery_overlay nav .next {
    align-content: center;
    display: -webkit-box;
    display: flex;
    height: 3.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: -1.375rem;
    padding: 0.3125rem;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 3.75rem;
  }
  .image_gallery_overlay nav .previous:focus .icon,
  .image_gallery_overlay nav .next:focus .icon,
  .image_gallery_overlay nav .previous:hover .icon,
  .image_gallery_overlay nav .next:hover .icon {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
  }
  .image_gallery_overlay nav .previous .icon,
  .image_gallery_overlay nav .next .icon {
    height: 2.75rem;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous {
    left: 5%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .image_gallery_overlay nav .next {
    right: 5%;
  }
  .image_gallery_overlay nav .close {
    background: none;
    height: 2.75rem;
    padding: 0.375rem;
    right: 1.25rem;
    top: 1.25rem;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .close:focus,
  .image_gallery_overlay nav .close:hover {
    outline: 0;
  }
  .image_gallery_overlay nav .close:focus .icon,
  .image_gallery_overlay nav .close:hover .icon {
    fill: #fff;
  }
  .image_gallery_overlay nav .close .icon {
    fill: #ffed00;
    height: 2rem;
    width: 2rem;
  }
  .infobanner_reachability {
    background-color: rgba(255, 237, 0, 0.95);
    left: 0;
    margin: 0.9375rem;
    max-height: 90vh;
    overflow: auto;
    padding: 1.25rem;
    position: fixed;
    right: 0;
    top: 2vh;
    z-index: 9991;
  }
  .infobanner_reachability h2 {
    margin-right: 4.5rem;
  }
  .infobanner_reachability a {
    color: #000000;
  }
  .infobanner_reachability.hide_infobanner_reachability {
    display: none;
  }
  .infobanner_reachability .close_infobanner_reachability {
    position: absolute;
    right: 1.2em;
    top: 1.2em;
    z-index: 10;
  }
  .infobanner_reachability .close_infobanner_reachability .icon {
    height: 2.25rem;
    width: 2.25rem;
  }
  .infobanner_reachability .close_infobanner_reachability .icon svg {
    fill: #000000;
    height: 2.25rem;
    width: 2.25rem;
  }
  .black_teaser_overview .black_teaser {
    min-height: 18.75rem;
    padding: 1.8em;
    border: 0.0625rem solid #fff;
    border-left: 0;
    border-top: 0;
    cursor: pointer;
    -webkit-box-flex: 1;
            flex-grow: 1;
    position: relative;
    text-decoration: none;
  }
  .black_teaser_overview .black_teaser > * {
    pointer-events: none;
  }
  .black_teaser_overview .black_teaser .headline {
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    -webkit-transition: opacity 1500ms ease;
    transition: opacity 1500ms ease;
    z-index: 20;
  }
  .black_teaser_overview .black_teaser:after {
    background-color: #000000;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: rotateY(0%);
            transform: rotateY(0%);
    -webkit-transition: background-color 1500ms ease, -webkit-transform 1500ms ease;
    transition: background-color 1500ms ease, -webkit-transform 1500ms ease;
    transition: transform 1500ms ease, background-color 1500ms ease;
    transition: transform 1500ms ease, background-color 1500ms ease, -webkit-transform 1500ms ease;
    z-index: -1;
  }
  .black_teaser_overview .black_teaser .title {
    letter-spacing: calc((1.25rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.25rem;
    color: #fff;
    font-weight: 300;
    margin-bottom: 0;
    max-width: 18.75rem;
    padding: 0;
    text-align: center;
    text-decoration: none;
    -webkit-transition: font-weight 300ms ease, color 300ms ease;
    transition: font-weight 300ms ease, color 300ms ease;
  }
  .black_teaser_overview .black_teaser .description {
    opacity: 0;
    -webkit-transition: opacity 1500ms ease;
    transition: opacity 1500ms ease;
    z-index: 10;
  }
  .black_teaser_overview .black_teaser .description .headline_card .title {
    color: #000000;
    margin-bottom: 1.2em;
    text-align: left;
    -webkit-transition: all 75ms ease;
    transition: all 75ms ease;
  }
  .black_teaser_overview .black_teaser .description ul {
    max-width: 28.125rem;
  }
  .black_teaser_overview .black_teaser:hover .title,
  .black_teaser_overview .black_teaser:active .title,
  .black_teaser_overview .black_teaser.active .title,
  .black_teaser_overview .black_teaser:focus .title,
  .black_teaser_overview .black_teaser.focus .title {
    color: #ffed00;
  }
  .black_teaser_overview .black_teaser.clicked:after {
    background-color: #c6c6c6;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -webkit-transition: background-color 1500ms ease, -webkit-transform 1500ms ease;
    transition: background-color 1500ms ease, -webkit-transform 1500ms ease;
    transition: transform 1500ms ease, background-color 1500ms ease;
    transition: transform 1500ms ease, background-color 1500ms ease, -webkit-transform 1500ms ease;
  }
  .black_teaser_overview .black_teaser.clicked .headline {
    opacity: 0;
    -webkit-transition: opacity 300ms ease;
    transition: opacity 300ms ease;
  }
  .black_teaser_overview .black_teaser.clicked .description {
    opacity: 1;
    -webkit-transition: opacity 1500ms ease;
    transition: opacity 1500ms ease;
  }
  .jobs_and_career .padding_bottom,
  .bachelor .padding_bottom,
  .trainee .padding_bottom {
    padding-bottom: 1.875rem;
  }
  .jobs_and_career .section,
  .bachelor .section,
  .trainee .section {
    padding-bottom: 1.875rem;
    padding-top: 1.875rem;
  }
  .thin_text {
    font-weight: 300;
  }
  .big_textpart {
    letter-spacing: calc((1.125rem + 2.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.125rem;
  }
  .backlink {
    margin-top: 2.5rem;
  }
  .font_family_senior {
    letter-spacing: calc((1.75rem + 4.5
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.75rem;
    color: #58595B;
    font-family: "Neue Helvetica", sans-serif;
    line-height: 0.75;
  }
  .jobs_and_career_sitetitle {
    -webkit-box-align: center;
            align-items: center;
    background: #000000;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 1.5625rem 0;
  }
  .jobs_and_career_sitetitle h1,
  .jobs_and_career_sitetitle .like_h1 {
    color: #fff;
    font-weight: 600;
    margin: 0;
  }
  .jobs_and_career_sitetitle .title_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .jobs_and_career_sitetitle .title {
    letter-spacing: calc((1.125rem + 0.75
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 1.125rem;
    color: #fff;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
  }
  .jobs_and_career_sitetitle .icon {
    fill: #ffed00;
    height: 1.25rem;
    margin-right: 0.9375rem;
    width: 1.25rem;
  }
  .jobs_and_career_sitetitle.team_entry_sitetitle {
    background: none;
  }
  .jobs_and_career_sitetitle.team_entry_sitetitle .row {
    width: 100%;
  }
  .jobs_and_career_sitetitle.team_entry_sitetitle .title_wrapper {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .jobs_and_career_sitetitle.team_entry_sitetitle .title_wrapper .title {
    max-width: none !important;
    position: relative;
  }
  .jobs_and_career_sitetitle.team_entry_sitetitle .title_wrapper .icon {
    position: absolute;
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 10;
  }
  .jobs_and_career_sitetitle.team_entry_sitetitle .title_wrapper .icon svg {
    height: 1.25rem;
    width: 1.25rem;
  }
  .jobs_and_career_sitetitle.team_entry_sitetitle h1,
  .jobs_and_career_sitetitle.team_entry_sitetitle .like_h1 {
    letter-spacing: calc((1.375rem + 6.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.375rem;
    color: #000000;
    font-family: "Neue Helvetica", sans-serif;
    font-weight: 400;
  }
  .yellow_text_area {
    letter-spacing: calc((1.375rem + 1.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.375rem;
    background: #ffed00;
    font-weight: 200;
  }
  .yellow_text_area a {
    letter-spacing: calc((1rem + 1.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1rem;
    color: #000000;
  }
  .yellow_text_area a.online {
    letter-spacing: calc((1.375rem + 1.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.375rem;
  }
  .application_process_text {
    letter-spacing: calc((1.125rem + 0.4375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.125rem;
  }
  .application_process_text strong {
    font-weight: 600;
  }
  .strong_medium strong {
    font-weight: 500;
  }
  .headline_with_arrow {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .headline_with_arrow .title {
    letter-spacing: calc((1.25rem + 1.375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 1.25rem;
    color: #000000;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  .headline_with_arrow .icon {
    fill: #ffed00;
    height: 1.25rem;
    margin-right: 0.6em;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 1.25rem;
  }
  .check_in_zero_emisson_text {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .trainee_headline {
    border-bottom: 0.1875rem solid #000000;
  }
  .trainee_headline p {
    letter-spacing: calc((1rem + 1.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1rem;
    margin-bottom: 0;
    opacity: 0;
    -webkit-transform: translateX(-3.125rem);
            transform: translateX(-3.125rem);
    -webkit-transition: all 600ms ease;
    transition: all 600ms ease;
  }
  .trainee_headline .title {
    letter-spacing: calc((1.25rem + 1.75
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 1.25rem;
    color: #000000;
    font-family: "Neue Helvetica", sans-serif;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 0.4em;
    opacity: 0;
    padding-top: 0;
    -webkit-transform: translateX(-6.25rem);
            transform: translateX(-6.25rem);
    -webkit-transition: all 900ms ease;
    transition: all 900ms ease;
  }
  .trainee_headline .icon {
    opacity: 0;
    -webkit-transform: translateX(-6.25rem);
            transform: translateX(-6.25rem);
    -webkit-transition: all 900ms ease;
    transition: all 900ms ease;
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms;
  }
  .trainee_headline.animated .icon {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: all 900ms ease;
    transition: all 900ms ease;
    -webkit-transition-delay: 300ms;
            transition-delay: 300ms;
  }
  .trainee_headline.animated p {
    opacity: 1;
    -webkit-transform: translateX(0rem);
            transform: translateX(0rem);
    -webkit-transition: all 600ms ease;
    transition: all 600ms ease;
  }
  .trainee_headline.animated .title {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: all 900ms ease;
    transition: all 900ms ease;
  }
  .title_headline {
    margin-bottom: 1.2em;
  }
  .textelement_overview .textelement.font_family_senior_title .title {
    letter-spacing: calc((1.75rem + 4.5
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.75rem;
    color: #58595B;
    font-family: "Neue Helvetica", sans-serif;
    line-height: 0.75;
  }
  .textelement_overview .textelement.font_family_senior_title .title_addition {
    letter-spacing: calc((0.9375rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    color: #000000;
    font-family: "Neue Helvetica", sans-serif;
    font-weight: 500;
    line-height: 1.2;
  }
  .textelement_overview .textelement.big_text {
    letter-spacing: calc((0.9375rem + 0.9375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
  }
  .textelement_overview .textelement ul {
    list-style: disc !important;
  }
  .textelement_overview a {
    color: #000000;
  }
  .textelement_overview.jobs_startpage_text .title {
    letter-spacing: calc((1.875rem + 1.875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.875rem;
  }
  .textelement_overview.trainee .textelement.font_family_senior_title .title {
    margin-bottom: 1.25rem;
  }
  .job_nav_overview.your_entry .entry {
    min-height: 12.5rem;
  }
  .job_nav_overview.your_entry .entry .title {
    text-transform: none;
  }
  .job_nav_overview .entry {
    -webkit-box-align: center;
            align-items: center;
    background: #000000;
    border: 0.0625rem solid #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
  }
  .job_nav_overview .entry .title {
    letter-spacing: calc((1.5625rem + 0.3125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.5625rem;
    color: #fff;
    font-weight: 300;
    margin-bottom: 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
  }
  .job_nav_overview .entry:hover .title,
  .job_nav_overview .entry:active .title,
  .job_nav_overview .entry.active .title,
  .job_nav_overview .entry.focus .title {
    color: #ffed00;
    -webkit-transition: color 300ms ease;
    transition: color 300ms ease;
  }
  .job_nav_overview .row {
    margin-left: 0;
    margin-right: 0;
  }
  .job_nav_overview .row > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .job_nav_overview .row > [class*="span"]:last-child .entry {
    background-color: #ffed00;
  }
  .job_nav_overview .row > [class*="span"]:last-child .entry .title {
    color: #000000;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  .job_nav_overview .row > [class*="span"]:last-child:hover .title,
  .job_nav_overview .row > [class*="span"]:last-child:active .title,
  .job_nav_overview .row > [class*="span"]:last-child.active .title,
  .job_nav_overview .row > [class*="span"]:last-child.focus .title {
    font-weight: 600;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  .phase_teaser {
    margin: 1.875rem 0;
  }
  .phase_teaser .phase_teaser_element {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-bottom: 3.75rem;
    padding: 0 3.75rem 0 0;
    position: relative;
  }
  .phase_teaser .phase_teaser_element .title {
    letter-spacing: calc((1.25rem + 1.75
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.25rem;
    color: #c6c6c6;
    margin-bottom: 2.0625rem;
    text-transform: uppercase;
  }
  .phase_teaser .phase_teaser_element .title_addition {
    color: #000000;
  }
  .phase_teaser .phase_teaser_element .description {
    letter-spacing: calc((1.125rem + 0.4375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.125rem;
    background: #000000;
    border-radius: 0.5rem;
    box-shadow: 0.1875rem 0.1875rem 0.3125rem 0 rgba(0, 0, 0, 0.27);
    color: #fff;
    -webkit-box-flex: 1;
            flex-grow: 1;
    min-height: 15.625rem;
    padding: 1.5625rem 7.5rem 3.75rem 1.375rem;
    position: relative;
  }
  .phase_teaser .phase_teaser_element .icon {
    position: absolute;
    right: -1.875rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    z-index: 1;
  }
  .phase_teaser .phase_teaser_element .icon svg {
    height: 5rem;
    width: 5rem;
  }
  .phase_teaser .phase_teaser_element_2 .icon {
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
  }
  .phase_teaser .phase_teaser_element_3 .icon {
    -webkit-transition-delay: 0.6s;
            transition-delay: 0.6s;
  }
  .application_process {
    border-bottom: 0.1875rem solid #000000;
    border-top: 0.1875rem solid #000000;
    margin: 3.125rem 0;
    padding: 2.4em 0;
  }
  .application_process ul {
    list-style: url("../images/list_style_image_circle_white.svg") !important;
  }
  .application_process ul li {
    margin-bottom: 1.875rem;
  }
  .job_teaser_overview .entry {
    margin-top: 0.9375rem;
    max-width: 25rem;
    overflow: hidden;
    position: relative;
  }
  .job_teaser_overview .entry .job_image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .job_teaser_overview .entry .content {
    padding: 0.3em;
    bottom: 0;
    color: #000000;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    -webkit-box-pack: justify;
            justify-content: space-between;
    left: 0;
    position: absolute;
    right: 0;
    text-decoration: none;
    text-transform: uppercase;
    top: 0;
    z-index: 10;
  }
  .job_teaser_overview .entry .content:after {
    background-color: #ffed00;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: rotateY(0%);
            transform: rotateY(0%);
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
    z-index: -1;
  }
  .job_teaser_overview .entry .content .title {
    color: #fff;
    font-size: 1.25rem;
    line-height: 1.2;
    margin: 0 1.25rem 0 0;
    text-shadow: -0.0625rem 0 #000000, 0 0.0625rem #000000, 0.0625rem 0 #000000, 0 -0.0625rem #000000;
    text-transform: uppercase;
    -webkit-transition: text-shadow 300ms ease;
    transition: text-shadow 300ms ease;
    width: 100%;
    word-wrap: keep-all;
  }
  .job_teaser_overview .entry .content .title.black_title {
    color: #000000;
    text-shadow: none;
  }
  .job_teaser_overview .entry .content .description {
    color: #000000;
    font-size: 1.25rem;
    font-weight: 600;
    height: 100%;
    margin: 0 1.25rem 0 0;
    opacity: 0;
    text-transform: uppercase;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    width: 100%;
    word-wrap: keep-all;
  }
  .job_teaser_overview .entry .content .description p {
    margin: 0;
    padding: 0;
  }
  .job_teaser_overview .entry .content:hover .title,
  .job_teaser_overview .entry .content:active .title,
  .job_teaser_overview .entry .content.active .title,
  .job_teaser_overview .entry .content.focus .title {
    text-shadow: none;
    -webkit-transition: text-shadow 300ms ease;
    transition: text-shadow 300ms ease;
  }
  .job_teaser_overview .entry .content:hover .description,
  .job_teaser_overview .entry .content:active .description,
  .job_teaser_overview .entry .content.active .description,
  .job_teaser_overview .entry .content.focus .description {
    opacity: 1;
    -webkit-transition: all 2100ms ease;
    transition: all 2100ms ease;
  }
  .job_teaser_overview .entry .content:hover:after,
  .job_teaser_overview .entry .content:active:after,
  .job_teaser_overview .entry .content.active:after,
  .job_teaser_overview .entry .content.focus:after {
    opacity: 1;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
  }
  .job_teaser {
    border-left: 0.0625rem solid #ffed00;
    padding-left: 1.25rem !important;
  }
  .job_teaser .job_title {
    letter-spacing: calc((1.5625rem + 3.4375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.5625rem;
    margin-bottom: 0.2em;
    text-transform: uppercase;
  }
  .job_teaser .additional_title {
    letter-spacing: calc((1.125rem + 1.375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.125rem;
    line-height: 1;
    max-width: 26.875rem;
    text-transform: uppercase;
  }
  .job_teaser .content_wrapper {
    margin-top: 3.125rem;
  }
  .job_teaser .goal {
    letter-spacing: calc((1.25rem + 1.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.25rem;
    font-weight: 600;
  }
  .job_teaser .goal .title {
    letter-spacing: calc((2.5rem + 2.5
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 2.5rem;
    color: #ffed00;
    font-weight: 200;
    line-height: 1;
    text-shadow: 0 0.25rem 0.3125rem rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
  }
  .job_teaser .conditions {
    letter-spacing: calc((1.125rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.125rem;
    font-family: "Neue Helvetica", sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 3.125rem;
    margin-right: 1.2em;
    margin-top: 3.125rem;
  }
  .job_teaser .conditions ul {
    padding-left: 1.125rem;
  }
  .job_teaser .conditions ul li {
    margin-bottom: 0;
  }
  .job_teaser .conditions .title {
    font-weight: 600;
    text-transform: uppercase;
  }
  .job_teaser .black_profile {
    letter-spacing: calc((1rem + 0.375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1rem;
    background: #000000;
    color: #fff;
    line-height: 1.14;
    padding: 2.5rem 1.25rem 2.5rem 0;
    position: relative;
  }
  .job_teaser .black_profile::after {
    background: #000000;
    bottom: 0;
    content: "";
    left: -312.5rem;
    position: absolute;
    right: 100%;
    top: 0;
    z-index: -1;
  }
  .job_teaser .photo_span {
    position: relative;
  }
  .job_teaser .photo_span .photo_wrapper {
    -webkit-box-flex: 1;
            flex-grow: 1;
    height: 50vh;
    overflow: hidden;
    position: relative;
  }
  .job_teaser .photo_span .photo_wrapper .photo {
    background-position: 50% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100vh !important;
    z-index: -1;
  }
  .job_teaser .jobs_button {
    -webkit-box-align: start;
            align-items: flex-start;
    bottom: 30%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    position: absolute;
    z-index: 100;
  }
  .job_teaser .jobs_button .button {
    border-radius: 0.3125rem;
    box-shadow: 0 0.25rem 0.3125rem rgba(0, 0, 0, 0.4);
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin: 1.25rem auto 0 0.9375rem;
    padding: 0.625rem;
    text-align: left;
  }
  .job_teaser .jobs_button .button .text {
    font-weight: 500;
    text-transform: uppercase;
  }
  .job_teaser .jobs_button .button span {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .trainerportrait {
    padding-bottom: 0.625rem;
    border-bottom: 0.0625rem solid #000000;
    margin-bottom: 5.625rem;
  }
  .trainerportrait .image_wrapper {
    min-height: 0.0625rem;
    position: relative;
  }
  .trainerportrait .image_wrapper .text_overlay {
    letter-spacing: calc((0.9375rem + 0.875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    bottom: 1.25rem;
    color: #ffed00;
    font-weight: 500;
    left: 1.25rem;
    max-width: 90%;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  .trainerportrait .image_wrapper .text_overlay p {
    margin: 0;
  }
  .trainerportrait .image_wrapper .text_overlay strong {
    letter-spacing: calc((1.375rem + 7.375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.375rem;
    color: #fff;
    font-family: "Neue Helvetica", sans-serif;
    font-weight: 500;
    line-height: 0.7;
  }
  .trainerportrait .description {
    letter-spacing: calc((0.9375rem + 0.875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    font-weight: 300;
    margin: 2.5rem 0 0 auto;
  }
  .kundenstimme {
    display: -webkit-box;
    display: flex;
  }
  .kundenstimme .row {
    width: 100%;
  }
  .kundenstimme .video_container {
    -webkit-box-flex: 1;
            flex-grow: 1;
    background: #000000;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
  }
  .kundenstimme .kundenstimme_background {
    -webkit-box-flex: 1;
            flex-grow: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .kundenstimme .content {
    position: relative;
    background: #000000;
    color: #fff;
    -webkit-box-flex: 1;
            flex-grow: 1;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding-bottom: 2.4em;
  }
  .kundenstimme .content a {
    color: #fff;
    text-decoration: none;
  }
  .kundenstimme .content a:hover,
  .kundenstimme .content a:focus {
    text-decoration: underline;
  }
  .kundenstimme .content .headline {
    margin-left: 2.4em;
  }
  .kundenstimme .content .headline h3 {
    display: inline;
    background: #ffed00;
    line-height: 1.3;
    letter-spacing: calc((1.25rem + 1.75
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 1.25rem;
    font-family: "Neue Helvetica", sans-serif;
    font-weight: 200;
    margin: 0 0 0.6em;
    padding-top: 0;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
  .kundenstimme .content .description_wrapper {
    margin-left: 2.4em;
    margin-right: 2.4em;
    max-width: 34.375rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
  .kundenstimme .content .description_wrapper ul {
    list-style: url("../images/list_style_image_circle_white.svg") !important;
  }
  .kundenstimme .content .link_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    font-size: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 2.4em;
    margin-bottom: 1.2em;
  }
  .kundenstimme .content .link_wrapper:hover svg {
    -webkit-transform: translateX(-0.625rem);
            transform: translateX(-0.625rem);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .kundenstimme .content .link_wrapper svg {
    fill: #ffed00;
    height: 3.75rem;
    padding-right: 0.625rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 3.125rem;
  }
  .kundenstimme .content .link_wrapper .text {
    color: #fff;
  }
  .language_select ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    margin-right: 1.25rem;
    width: 100%;
  }
  .language_select ul li {
    letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.875rem;
    line-height: 1;
  }
  .language_select ul li:first-child:after {
    color: #000000;
    content: "\002F";
  }
  .language_select ul a {
    color: #000000;
    padding: 0 0.3em;
    text-decoration: none;
  }
  .language_select ul a:hover,
  .language_select ul a.active,
  .language_select ul a:focus {
    color: #ffed00;
  }
  .legal_notice_tax_aut_aab {
    margin-bottom: 0.6em;
    padding-top: 0.6em;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] {
    display: block;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] > span {
    display: -webkit-box;
    display: flex;
  }
  .legal_notice_tax_aut_aab .icon {
    display: block;
    fill: #e44e4e;
    height: 1.5rem;
    margin-right: 0.48em;
    min-width: 1.5rem;
    width: 1.5rem;
  }
  main {
    overflow: hidden;
  }
  p.small {
    font-size: 70%;
    margin-left: 1.875rem;
  }
  h1 .sub_h1 {
    display: inline-block;
    text-transform: none!important;
    margin-bottom: 1.875rem;
    margin-top: 1.875rem;
  }
  .grey_bow {
    position: relative;
  }
  .grey_bow:after {
    background: url("../images/bogen.svg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 60%;
    content: "";
    left: -5%;
    position: absolute;
    right: -5%;
    top: 0;
    z-index: -1;
  }
  .styled_like_h1 {
    letter-spacing: calc((1.375rem + 1.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 1.375rem;
    color: #fff;
    font-family: "Neue Helvetica", sans-serif;
    font-weight: 500;
    line-height: 1;
    margin: 0 0 0.6em;
    text-transform: uppercase;
  }
  .shortlinks {
    position: relative;
  }
  .shortlinks .fixed_wrapper {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: start;
            justify-content: flex-start;
    left: 0;
    margin: 0 auto;
    margin-top: 1.2em;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: 2000;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .shortlinks .fixed_wrapper a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    pointer-events: auto;
    margin-bottom: 1.2em;
  }
  .shortlinks .fixed_wrapper a .text {
    color: #000000;
    font-size: 0.9375rem;
    max-width: 0;
    opacity: 0;
    padding: 0.3125rem 0.625rem 0.3125rem 1.25rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    z-index: 50;
    white-space: nowrap;
  }
  .shortlinks .fixed_wrapper a .text::after {
    background: #ffed00;
    bottom: 0;
    content: "";
    left: -1.2em;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .shortlinks .fixed_wrapper a .icon {
    position: relative;
    z-index: 60;
    border: 5px solid #000000;
    background: #9d9d9c;
    border-radius: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .shortlinks .fixed_wrapper a svg {
    height: 2.1875rem;
    width: 2.1875rem;
    fill: #ffed00;
    margin: 0.625rem;
  }
  .shortlinks .fixed_wrapper a.contact_link_main {
    margin: 0 0 1.2em 0;
  }
  .shortlinks .fixed_wrapper a:hover .text,
  .shortlinks .fixed_wrapper a:focus .text {
    max-width: 16.5625rem;
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .icon_headline {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 2.4em;
  }
  .icon_headline.headline_margin h2,
  .icon_headline.headline_margin h3 {
    margin: 0 0 0.6em;
    text-transform: none;
  }
  .icon_headline img {
    max-width: 9.375rem;
    margin-right: 2.4em;
  }
  .icon_headline h2 {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }
  .content_slider.full_pagination .icon_headline {
    background-color: #f2f2f2;
    margin-bottom: 0;
  }
  .content_slider.full_pagination .icon_headline.black {
    width: 100%;
    background-color: #000;
  }
  a.black_link,
  .black_link a {
    color: #000000;
    text-decoration: none;
  }
  a.black_link:hover,
  .black_link a:hover {
    color: #666666;
  }
  .yellow_light {
    color: #ffed00;
    font-weight: 300;
  }
  .thin,
  .black_thin {
    color: #000000;
    font-weight: 200;
  }
  .smaller_font {
    display: -webkit-box;
    display: flex;
    font-size: 40%;
  }
  .center {
    text-align: center;
  }
  .grey {
    background: #f2f2f2;
    position: relative;
  }
  .grey:before {
    background: #f2f2f2;
    bottom: 0;
    content: "";
    left: -125rem;
    position: absolute;
    right: 100%;
    top: 0;
  }
  .grey:after {
    background: #f2f2f2;
    bottom: 0;
    content: "";
    left: 100%;
    position: absolute;
    right: -125rem;
    top: 0;
  }
  .middle_grey {
    background: #f2f2f2;
    position: relative;
  }
  .middle_grey:before {
    background: #f2f2f2;
    bottom: 0;
    content: "";
    left: -125rem;
    position: absolute;
    right: 100%;
    top: 0;
  }
  .middle_grey:after {
    background: #f2f2f2;
    bottom: 0;
    content: "";
    left: 100%;
    position: absolute;
    right: -125rem;
    top: 0;
  }
  .black {
    background: #000000;
    color: #fff;
    position: relative;
  }
  .black:before {
    background: #000000;
    bottom: 0;
    content: "";
    left: -312.5rem;
    position: absolute;
    right: 100%;
    top: 0;
  }
  .black:after {
    background: #000000;
    bottom: 0;
    content: "";
    left: 100%;
    position: absolute;
    right: -312.5rem;
    top: 0;
  }
  .black h2,
  .black .like_h2 {
    color: #ffed00;
  }
  .black ul {
    list-style: url("../images/list_style_image_rectangle.svg") !important;
  }
  .black_white h2,
  .black_white .like_h2 {
    color: #fff;
  }
  .yellow {
    background: #ffed00;
    position: relative;
  }
  .yellow:before {
    background: #ffed00;
    bottom: 0;
    content: "";
    left: -312.5rem;
    position: absolute;
    right: 100%;
    top: 0;
  }
  .yellow:after {
    background: #ffed00;
    bottom: 0;
    content: "";
    left: 100%;
    position: absolute;
    right: -312.5rem;
    top: 0;
  }
  .yellow a {
    color: #000000;
  }
  .in_container:before {
    left: 0;
  }
  .in_container:after {
    right: 0;
  }
  .excess:before {
    left: -5%;
  }
  .excess:after {
    right: -5%;
  }
  .section_picture {
    position: relative;
  }
  .section_picture:before {
    background: #ffed00;
    content: "";
    height: 100%;
    position: absolute;
    width: 0.0625rem;
    left: 1.25rem;
  }
  .section_picture figure {
    margin-bottom: 0;
    max-height: 100vh;
    overflow: hidden;
  }
  .section_picture img {
    width: 100%;
  }
  figure {
    max-width: 100%!important;
  }
  figure img {
    width: 100%;
  }
  .product_img {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .product_img figure {
    max-width: 60rem!important;
  }
  .section_big {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .section {
    padding: 3.75rem 0;
  }
  .section_small {
    padding: 2.8125rem 0;
  }
  .grey_section_small {
    padding: 1.875rem 0;
  }
  .section_small_bottom {
    padding: 5.625rem 0 2.8125rem;
  }
  .section_small_top_margin {
    margin: 2.8125rem 0 5.625rem;
  }
  .section_small_bottom_margin {
    margin: 5.625rem 0 2.8125rem;
  }
  .padding_top,
  .container.padding_top {
    padding-top: 5.625rem;
  }
  .padding_top_small,
  .container.padding_top_small {
    padding-top: 2.8125rem;
  }
  .padding_bottom,
  .container.padding_bottom {
    padding-bottom: 5.625rem;
  }
  .padding_bottom_small,
  .container.padding_bottom_small {
    padding-bottom: 2.8125rem;
  }
  .margin_top {
    margin-top: 5.625rem;
  }
  .margin_top_small {
    margin-top: 0.3125rem;
  }
  .margin_top_double {
    margin-top: 5.625rem;
  }
  .margin_bottom_double {
    margin-bottom: 5.625rem;
  }
  .margin_small {
    margin: 2.8125rem 0;
  }
  .margin_bottom_small {
    margin-bottom: 2.8125rem;
  }
  .margin_bottom {
    margin-bottom: 5.625rem;
  }
  .bigger_font {
    font-size: 1rem;
  }
  .startpage .section_1 h1 {
    margin-bottom: 0;
  }
  .startpage .section_1 ul {
    font-weight: 600;
  }
  .startpage .section_1 .column_1 p {
    line-height: 1;
    margin-bottom: 2.1875rem;
    font-size: 2.1875rem;
  }
  .startpage .section_2 .column_2 p:last-child {
    margin-top: 4.1875rem;
  }
  .startpage .section_4 {
    background: #000000;
    position: relative;
  }
  .startpage .section_4:before {
    background: #000000;
    bottom: 0;
    content: "";
    left: -125rem;
    position: absolute;
    right: 100%;
    top: 0;
  }
  .startpage .section_4:after {
    background: #000000;
    bottom: 0;
    content: "";
    left: 100%;
    position: absolute;
    right: -125rem;
    top: 0;
  }
  .two_columns .two_columns_text:last-child {
    padding-top: 2.8125rem;
  }
  .half_size_video {
    margin: 0 auto;
  }
  .video_wrapper {
    height: 0;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper .video[poster] {
    width: 100%!important;
  }
  .video_wrapper .icon_wrapper {
    bottom: 1.25rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 1.25rem;
    position: absolute;
    z-index: 90;
  }
  .video_wrapper .icon_wrapper .icon {
    cursor: pointer;
  }
  .video_wrapper .icon_wrapper .icon:hover {
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
  }
  .video_wrapper .icon_wrapper .icon:hover svg {
    opacity: .8;
  }
  .video_wrapper .icon_wrapper .icon svg {
    fill: #f2f2f2;
    height: 3.125rem;
    opacity: .7;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 3.125rem;
  }
  .video_container video {
    width: 100%;
  }
  .volume_button_wrapper {
    position: relative;
    top: 0;
    z-index: 1000;
  }
  .volume_button_wrapper .volume_button {
    height: 2.75rem;
    right: 0.625rem;
    top: 2.5rem;
    width: 2.75rem;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    position: absolute;
  }
  .volume_button_wrapper .volume_button .icon {
    height: 2.5rem;
    width: 2.5rem;
    -webkit-box-align: center;
            align-items: center;
    border: 0.125rem solid #ffed00;
    border-radius: 100%;
    display: -webkit-box;
    display: flex;
    fill: #ffed00;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .volume_button_wrapper .volume_button .icon svg {
    height: 1.25rem;
    width: 1.25rem;
  }
  .smaller_headline h2,
  .smaller_headline .like_h2 {
    font-size: 130%;
    padding-top: 2.4em;
  }
  .smaller_headline .address_mares h2 {
    padding-top: 0;
  }
  .smaller_headline a {
    color: #000000;
  }
  .smaller_headline a:hover {
    color: #808080;
  }
  .seal {
    margin-top: -6.25rem;
    max-width: 28.125rem !important;
    position: relative;
    z-index: 100;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: relative;
    z-index: 9990;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 600;
    height: 2.25rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 1.625rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #fff;
    display: block;
    height: 0.25rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.1875rem;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.1875rem;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu label:hover .line {
    background: #ffed00;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
  .offcanvas_menu {
    display: block;
    font-size: 1.125rem;
    left: 0;
    margin-top: 9.375rem;
    min-height: 34.375rem;
    opacity: 0;
    overflow: auto;
    padding: 0.9375rem;
    position: fixed;
    top: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 5000;
    right: 0;
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a {
    color: #ffed00;
  }
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li:hover > a {
    color: #ffed00;
  }
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > a {
    letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.875rem;
    color: #666666;
    display: block;
    font-weight: 500;
    line-height: 1.5rem;
  }
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > a.active,
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > a:hover {
    color: #ffed00;
  }
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > ul > li ul li.expanded > a {
    color: #ffed00;
  }
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > ul > li ul li.expanded > a:hover,
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > ul > li ul li.expanded > a.active {
    color: #ffed00;
  }
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > ul a {
    letter-spacing: calc((0.8125rem + 0.3125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.8125rem;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    padding: 0.3125rem 0;
  }
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > ul a.active,
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > ul a:hover {
    color: #ffed00;
  }
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > ul ul a {
    font-weight: 300;
  }
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > ul ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content {
    background-color: rgba(255, 237, 0, 0.95);
    height: 100%;
    overflow: auto;
    padding: 1.25rem;
  }
  .offcanvas_menu .offcanvas_menu_content a {
    text-decoration: none;
    text-transform: uppercase;
  }
  .offcanvas_menu .offcanvas_menu_content > ul {
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    padding: 0 1.2em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a {
    color: #666666;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > ul {
    display: block;
    margin: 0 0 0.9375rem 1.25rem;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li:hover > a {
    color: #666666;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.875rem;
    color: #000000;
    display: block;
    font-weight: 500;
    line-height: 1.5rem;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover {
    color: #666666;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul {
    display: none;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li {
    margin-left: 1.2em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li.toggle_link {
    letter-spacing: calc((0.8125rem + 0.3125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.8125rem;
    font-weight: 500;
    margin-left: 0;
    margin-top: 0.6em;
    text-transform: uppercase;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li.toggle_link > ul {
    font-weight: 300;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li.toggle_link.expanded > ul {
    max-height: 31.25rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li ul.jobs_sub_sub {
    display: block;
    margin: 0 0 0.9375rem 1.25rem;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li ul li.expanded > a {
    color: #666666;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li ul li.expanded > a:hover,
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li ul li.expanded > a.active {
    color: #666666;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li ul li.expanded > ul {
    display: block;
    margin: 0 0 0.9375rem 1.25rem;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul a {
    letter-spacing: calc((0.8125rem + 0.3125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.8125rem;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    padding: 0.3125rem 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul a.active,
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul a:hover {
    color: #666666;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul a.active .icon,
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul a:hover .icon {
    fill: #666666;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul a .icon {
    display: -webkit-inline-box;
    display: inline-flex;
    height: 1.125rem;
    margin-left: 0.6em;
    width: 1.125rem;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul ul ul {
    display: none;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.6em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.6em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.3em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(0, 0, 0, 0.92);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.2em;
  }
  .popup_close {
    background: #ffed00;
    border-radius: 0.125rem 0.125rem 0 0;
    float: right;
    height: 1.8rem;
    margin-right: 0.0625rem;
    padding: 0.34285714em;
    text-align: center;
    width: 2.25rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 137.5rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.92);
    left: 0;
    position: absolute;
    right: 0;
    top: 1.8rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #d9d9d9;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .product_detail_overview .product_detail {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin-bottom: 0.6em;
    min-height: 0.0625rem;
  }
  .product_detail_overview .product_detail .title_wrapper {
    background: #000000;
    padding: 0.9375rem;
  }
  .product_detail_overview .product_detail .title_wrapper .title {
    letter-spacing: calc((0.9375rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    color: #fff;
    font-weight: 600;
    line-height: 1.3;
    margin: 0;
    padding: 0;
  }
  .product_detail_overview .product_detail .title_wrapper strong {
    color: #ffed00;
  }
  .product_detail_overview .product_detail .image {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 0.0625rem;
  }
  .product_detail_overview .product_detail .description {
    background: #dadada;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: start;
            justify-content: flex-start;
    padding: 2.8125rem 0.9375rem 0.9375rem;
  }
  .product_detail_overview .product_detail .description p {
    border-bottom: 0.0625rem solid #000000;
    margin-bottom: 1.2em;
    padding-bottom: 1.2em;
  }
  .product_detail_overview .product_detail .description p:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .register {
    margin: 0.9375rem 0 0;
  }
  .wavebionix .register {
    margin: 0.9375rem 0;
  }
  .register .register_tab .register_trigger {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .register .register_tab .register_trigger .title {
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0.6em 1.2em;
    text-align: center;
  }
  .register .register_tab .register_trigger .title:hover,
  .register .register_tab .register_trigger .title:focus {
    background: #ffed00;
    color: #000000;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .wavebionix .register .register_tab .register_trigger .title {
    border: 0.0625rem solid #fff;
  }
  .register .register_tab .register_toggle {
    height: 0;
    min-height: 0;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .register .register_tab.active .register_toggle {
    height: 100%;
    min-height: 100%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .register .register_tab.active .register_trigger .title {
    background: #ffed00;
    color: #000000;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin-right: 1.5625rem;
  }
  form[role="search"] button {
    background: none;
    border: 0;
    height: 2.25rem;
    width: 2.25rem;
  }
  form[role="search"] button .icon {
    fill: #000000;
    flex-shrink: 0;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  form[role="search"] button .icon svg {
    height: 2rem;
    width: 2rem;
  }
  form[role="search"] button[disabled] .icon {
    fill: #000000;
  }
  form[role="search"] button[disabled] ~ input[type="search"] {
    max-width: 0;
    padding: 0;
  }
  form[role="search"] button ~ input[type="search"] {
    border-bottom: 0.0625rem solid #d9d9d9;
    max-width: 100%;
    padding: 0 0.6em;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    width: 4.375rem;
  }
  form[role="search"] button:focus,
  form[role="search"] button:hover {
    cursor: pointer;
  }
  form[role="search"] button:focus ~ input[type="search"],
  form[role="search"] button:hover ~ input[type="search"] {
    max-width: 100%!important;
  }
  form[role="search"] button:focus .icon,
  form[role="search"] button:hover .icon {
    fill: #000000;
  }
  form[role="search"]:focus input[type="search"],
  form[role="search"]:hover input[type="search"] {
    border-bottom: 0.0625rem solid #d9d9d9;
    max-width: 100%;
    padding: 0 0.6em;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    width: 4.375rem;
  }
  form[role="search"]:focus button ~ input[type="search"],
  form[role="search"]:hover button ~ input[type="search"] {
    max-width: 100%!important;
  }
  form[role="search"] input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    color: #000000;
    height: 2.25rem;
    margin: 0;
    max-width: 0;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, width 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, width 300ms, z-index 300ms;
    width: 0;
  }
  form[role="search"] input[type="search"]:placeholder {
    display: none;
  }
  form[role="search"] input[type="search"]:focus {
    color: #000000;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  form[role="search"] input[type="search"]::-webkit-search-cancel-button,
  form[role="search"] input[type="search"]::-webkit-search-decoration,
  form[role="search"] input[type="search"]::-webkit-search-results-button,
  form[role="search"] input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search a {
    color: #666666;
  }
  .search dl {
    margin: 0 0 1.8em;
    padding-top: 0.6em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .slider_second,
  .slider_third,
  .slider {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    height: 12.5rem;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.2em;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .slider_second.big_pic_slider,
  .slider_third.big_pic_slider,
  .slider.big_pic_slider {
    height: 18.75rem;
    margin: 0 auto;
    max-width: 60%;
  }
  .slider_second.big_pic_slider .slide,
  .slider_third.big_pic_slider .slide,
  .slider.big_pic_slider .slide {
    left: 0;
    margin: 0 auto;
    max-width: 40%;
    right: 0;
  }
  .slider_second .slide,
  .slider_third .slide,
  .slider .slide {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: 600ms;
    transition: 600ms;
    visibility: hidden;
    width: 100%;
    z-index: 1;
  }
  .slider_second .slide.active,
  .slider_third .slide.active,
  .slider .slide.active {
    left: 0;
    visibility: visible;
    z-index: 10;
  }
  .slider_second .slide .slider_content,
  .slider_third .slide .slider_content,
  .slider .slide .slider_content {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    width: 100%;
  }
  .slider_second .slider_navigation,
  .slider_third .slider_navigation,
  .slider .slider_navigation {
    display: none;
  }
  .slider_second .slider_navigation .arrows,
  .slider_third .slider_navigation .arrows,
  .slider .slider_navigation .arrows,
  .slider_second .slider_navigation .icon,
  .slider_third .slider_navigation .icon,
  .slider .slider_navigation .icon {
    height: 2rem;
    width: 2rem;
  }
  .slider_second .slider_navigation .arrows svg,
  .slider_third .slider_navigation .arrows svg,
  .slider .slider_navigation .arrows svg,
  .slider_second .slider_navigation .icon svg,
  .slider_third .slider_navigation .icon svg,
  .slider .slider_navigation .icon svg {
    height: 2rem;
    width: 2rem;
  }
  .slider_second .slider_navigation .slide_nav,
  .slider_third .slider_navigation .slide_nav,
  .slider .slider_navigation .slide_nav {
    fill: #000000;
    position: absolute;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .slider_second .slider_navigation .slide_nav:focus,
  .slider_third .slider_navigation .slide_nav:focus,
  .slider .slider_navigation .slide_nav:focus,
  .slider_second .slider_navigation .slide_nav:hover,
  .slider_third .slider_navigation .slide_nav:hover,
  .slider .slider_navigation .slide_nav:hover {
    fill: #ffed00;
    opacity: 0.6;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .slider_second .slider_navigation .slide_nav.prev_slide,
  .slider_third .slider_navigation .slide_nav.prev_slide,
  .slider .slider_navigation .slide_nav.prev_slide {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .slider_second .slider_without_preview .slide.prev,
  .slider_third .slider_without_preview .slide.prev,
  .slider .slider_without_preview .slide.prev,
  .slider_second .slider_without_preview .slide.next,
  .slider_third .slider_without_preview .slide.next,
  .slider .slider_without_preview .slide.next {
    opacity: 0;
    visibility: hidden;
  }
  .slider_fade {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    height: 12.5rem;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.2em;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .slider_fade .slide {
    bottom: 0;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transition: opacity 600ms;
    transition: opacity 600ms;
    width: 100%;
    z-index: 1;
  }
  .slider_fade .slide.active {
    left: 0;
    opacity: 1;
    -webkit-transition: opacity 600ms;
    transition: opacity 600ms;
    z-index: 10;
  }
  .slider_fade .slide .slider_content {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    width: 100%;
  }
  .slider_fade .slider_navigation {
    display: none;
  }
  .slider_fade .slider_navigation .arrows,
  .slider_fade .slider_navigation .icon {
    height: 2rem;
    width: 2rem;
  }
  .slider_fade .slider_navigation .arrows svg,
  .slider_fade .slider_navigation .icon svg {
    height: 2rem;
    width: 2rem;
  }
  .slider_fade .slider_navigation .slide_nav {
    fill: #000000;
    position: absolute;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .slider_fade .slider_navigation .slide_nav:focus,
  .slider_fade .slider_navigation .slide_nav:hover {
    fill: #ffed00;
    opacity: 0.6;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .slider_fade .slider_navigation .slide_nav.prev_slide {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .social_media_legal_notice {
    padding: 1.2em;
  }
  .social_media_legal_notice .company_logo {
    margin: 0 auto;
    max-width: 25rem;
    text-align: center;
  }
  .social_media_links {
    padding-top: 0.3em;
  }
  .social_media_links ul {
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .social_media_links li {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    list-style: none;
    margin: 0 0.125rem;
    padding: 0;
  }
  .social_media_links a {
    border-radius: 0.125rem;
    color: #414245;
    display: inline-block;
  }
  .social_media_links a:hover .icon {
    fill: #808080;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .social_media_links a .icon {
    display: block;
    fill: #414245;
    height: 1.6875rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 1.6875rem;
  }
  .social_media_links a.xing .icon {
    height: 1.875rem;
    width: 1.875rem;
  }
  .social_media_overview {
    padding-top: 0.3em;
  }
  .social_media_overview .description {
    text-align: center;
  }
  .social_media_overview .entries {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    flex-wrap: wrap;
  }
  .social_media_overview a {
    border-radius: 50%;
    color: #414245;
    display: -webkit-inline-box;
    display: inline-flex;
    border: 0.0625rem solid #414245;
    padding: 1.2em;
    margin: 1.2em;
  }
  .social_media_overview a:hover .icon {
    fill: #808080;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .social_media_overview a .icon {
    display: block;
    fill: #414245;
    height: 5rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 5rem;
  }
  .social_media_share_wrapper {
    position: relative;
  }
  .social_media_share {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: center;
            align-items: center;
    margin-top: 2.4em;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    padding: 0;
  }
  .social_media_share li {
    list-style: none;
    margin: 0 0.3em;
    padding: 0;
  }
  .social_media_share a {
    display: block;
    color: #000000;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
  }
  .social_media_share a:hover,
  .social_media_share a:focus {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .social_media_share a .icon {
    border-radius: 50%;
    border: 0.0625rem solid #9d9d9c;
    height: 2.875rem;
    width: 2.875rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .social_media_share a .icon svg {
    height: 2.125rem;
    width: 2.125rem;
    fill: #9d9d9c;
  }
  .social_media_share .share_info {
    color: #9d9d9c;
    text-align: center;
    text-transform: uppercase;
  }
  .steuernews_preview {
    margin-bottom: 0.6em;
  }
  .steuernews_preview .news_wrapper {
    border-left: 0.0625rem solid #000000;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    padding-top: 1.875rem;
  }
  .steuernews_preview a {
    color: #000000;
    display: inline-block;
    font-size: 1rem;
    text-decoration: none;
    text-transform: uppercase;
  }
  .steuernews_preview a:hover svg {
    -webkit-transform: translateX(-0.625rem);
            transform: translateX(-0.625rem);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .steuernews_preview .link_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .steuernews_preview .link_wrapper svg {
    fill: #ffed00;
    height: 3.75rem;
    padding-right: 0.625rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 3.125rem;
  }
  .steuernews_preview .image {
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
    padding-left: 1.5625rem;
  }
  .steuernews_preview .teaser_text {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-top: 1.2em;
    -webkit-box-ordinal-group: 3;
            order: 2;
    padding-left: 1.5625rem;
  }
  .news_entity h1 {
    font-weight: 200;
    text-transform: none;
  }
  .news_entity a {
    color: #000000;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
  }
  .news_entity a:hover svg {
    -webkit-transform: translateX(-0.625rem);
            transform: translateX(-0.625rem);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .news_entity .text_wrapper {
    background: #f2f2f2;
    margin: 0 auto;
    max-width: 96.875rem;
    padding: 3.75rem 0 2.8125rem;
    position: relative;
  }
  .news_entity .text_wrapper:before {
    background: #f2f2f2;
    bottom: 0;
    content: "";
    left: -125rem;
    position: absolute;
    right: 100%;
    top: 0;
  }
  .news_entity .text_wrapper:after {
    background: #f2f2f2;
    bottom: 0;
    content: "";
    left: 100%;
    position: absolute;
    right: -125rem;
    top: 0;
  }
  .news_entity .news_image {
    overflow: hidden;
  }
  .news_entity .news_image img {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  .news_entity .link_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .news_entity .link_wrapper svg {
    fill: #ffed00;
    height: 3.75rem;
    padding-right: 0.625rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 3.125rem;
  }
  .news_entity .news_text {
    margin: 0 auto;
    max-width: 96.875rem;
    padding: 2.8125rem 0;
  }
  .news_entity .gallery {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
  }
  .news_entity .gallery .figure {
    flex-basis: 23%;
    margin: 1.2em;
  }
  .news_entity .creation_date {
    margin-top: 1.8em;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_year {
    padding-top: 4.375rem !important;
    text-align: center;
  }
  .news_year .button {
    background: #f2f2f2;
    border: 0.0625rem solid #f2f2f2;
  }
  .news_year .button.active,
  .news_year .button:hover {
    background: #cccccc;
    border: 0.0625rem solid #cccccc;
  }
  .team_overview .entry {
    overflow: hidden;
    position: relative;
  }
  .team_overview .entry:hover .description,
  .team_overview .entry:focus .description,
  .team_overview .entry.clicked .description {
    opacity: 1;
    -webkit-transition: opacity 600ms ease;
    transition: opacity 600ms ease;
  }
  .team_overview .entry:hover .photo,
  .team_overview .entry:focus .photo,
  .team_overview .entry.clicked .photo {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
    -webkit-transition: all 600ms ease;
    transition: all 600ms ease;
  }
  .team_overview .entry:hover .title,
  .team_overview .entry:focus .title,
  .team_overview .entry.clicked .title,
  .team_overview .entry:hover .info,
  .team_overview .entry:focus .info,
  .team_overview .entry.clicked .info {
    color: #000000;
    text-shadow: none;
  }
  .team_overview .entry .title {
    letter-spacing: calc((1.25rem + 1.375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.25rem;
    color: #fff;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-shadow: -0.0625rem 0 #000000, 0 0.0625rem #000000, 0.0625rem 0 #000000, 0 -0.0625rem #000000;
  }
  .team_overview .entry .photo,
  .team_overview .entry .bg_photo {
    height: 0;
    opacity: 1;
    padding-bottom: 80%;
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transition: all 600ms ease;
    transition: all 600ms ease;
    width: 100%;
  }
  .team_overview .entry .photo img,
  .team_overview .entry .bg_photo img {
    display: block;
    max-width: 100%;
    width: 100%;
  }
  .team_overview .entry .bg_photo {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .team_overview .entry .info {
    letter-spacing: calc((0.875rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.875rem;
    color: #fff;
    left: 1.25rem;
    position: absolute;
    right: 1.25rem;
    text-shadow: -0.0625rem 0 #000000, 0 0.0625rem #000000, 0.0625rem 0 #000000, 0 -0.0625rem #000000;
    top: 65%;
    z-index: 10;
  }
  .team_overview .entry .info .job_title {
    font-weight: bold;
  }
  .team_overview .entry .info.align_right {
    text-align: right;
  }
  .team_overview .entry .description {
    letter-spacing: calc((1.125rem + 0.1875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.125rem;
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 50%;
    -webkit-box-pack: justify;
            justify-content: space-between;
    left: 1.25rem;
    opacity: 0;
    position: absolute;
    right: 1.25rem;
    top: 1.8125rem;
    -webkit-transition: opacity 600ms ease;
    transition: opacity 600ms ease;
    z-index: 11;
  }
  .team_overview .entry .description ul {
    line-height: 1;
  }
  .team_overview .entry .more_about {
    color: #000000;
    display: inline;
    position: relative;
    text-decoration: none;
    z-index: 0;
  }
  .team_overview .entry .more_about::after {
    background-color: #000000;
    bottom: 0.0625rem;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: left 300ms ease, right 300ms ease, opacity 300ms ease;
    transition: left 300ms ease, right 300ms ease, opacity 300ms ease;
  }
  .team_overview .entry .more_about:focus::after,
  .team_overview .entry .more_about:hover::after {
    left: 0;
    opacity: 1;
    right: 0;
  }
  .team_entry.section {
    padding: 0!important;
  }
  .team_entry .font_family_senior {
    letter-spacing: calc((1.75rem + 2
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.75rem;
  }
  .team_entry .name {
    letter-spacing: calc((0.9375rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    font-weight: 600;
  }
  .team_entry .name .first_name {
    letter-spacing: calc((1rem + 1.875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1rem;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .team_entry .black_profile {
    letter-spacing: calc((1rem + 0.375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1rem;
    background: #000000;
    color: #ffed00;
    padding: 6.25rem 2.5rem 6.25rem 0;
    position: relative;
  }
  .team_entry .black_profile p {
    margin: 0;
  }
  .team_entry .black_profile strong {
    font-weight: 400;
  }
  .team_entry .black_profile::after {
    background: #000000;
    bottom: 0;
    content: "";
    left: -312.5rem;
    position: absolute;
    right: 100%;
    top: 0;
    z-index: -1;
  }
  .team_entry .interview {
    margin-right: 1.875rem;
  }
  .team_entry .interview h2 {
    margin-top: 0.9375rem;
  }
  .team_entry .photo_wrapper {
    -webkit-box-flex: 1;
            flex-grow: 1;
    overflow: hidden;
    position: relative;
  }
  .team_entry .photo_wrapper .photo {
    background-position: 50% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100% !important;
    z-index: -1;
  }
  .team_entry .info {
    margin-bottom: 0.6em;
  }
  .team_entry h2 strong {
    letter-spacing: calc((0.9375rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    display: block;
    line-height: 1.3;
    margin-top: 0.3125rem;
  }
  .teaser_arrow_animation {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding: 2.5rem 0;
    position: relative;
  }
  .teaser_arrow_animation.yellow_strong strong {
    color: #ffed00;
  }
  .teaser_arrow_animation.yellow h2,
  .teaser_arrow_animation.yellow p,
  .teaser_arrow_animation.yellow ul {
    color: #000000;
    list-style: url("../images/list_style_image_circle.svg") !important;
  }
  .teaser_arrow_animation h2 {
    color: #ffed00;
    margin-bottom: 0;
  }
  .teaser_arrow_animation.white_headline h2 {
    color: #fff;
  }
  .teaser_arrow_animation.white_headline ul {
    list-style: url("../images/list_style_image_circle_yellow.svg") !important;
  }
  .teaser_arrow_animation p,
  .teaser_arrow_animation h3 {
    color: #fff;
  }
  .teaser_arrow_animation ul,
  .teaser_arrow_animation p {
    padding-top: 1.875rem;
  }
  .teaser_arrow_animation.animated .description {
    opacity: 1;
    -webkit-transition: opacity 1500ms;
    transition: opacity 1500ms;
  }
  .teaser_arrow_animation.animated .teaser_image {
    margin-right: 0 !important;
    -webkit-transition: margin-right 1500ms;
    transition: margin-right 1500ms;
  }
  .teaser_arrow_animation .description {
    opacity: 0;
    -webkit-transition: opacity 1500ms;
    transition: opacity 1500ms;
  }
  .teaser_arrow_animation .teaser_image {
    display: none;
  }
  .teaser_arrow_animation .teaser_image::after {
    background: #ffed00;
    bottom: 0;
    content: "";
    left: 100%;
    position: absolute;
    right: -312.5rem;
    top: 0;
    z-index: 10;
  }
  .teaser_arrow_animation .download_overview .touch_link .title {
    color: #ffed00;
    margin-bottom: 0;
    text-decoration: none;
  }
  .teaser_arrow_animation .download_overview .touch_link .title:hover {
    text-decoration: none;
  }
  .teaser_arrow_animation .download_overview .touch_link p {
    margin-bottom: 1.2em;
    text-decoration: underline;
  }
  .teaser_arrow_animation .download_overview .touch_link .text {
    font-size: 105%;
    padding-top: 0;
  }
  .teaser_arrow_animation .download_overview .touch_link .text:hover {
    text-decoration: none;
  }
  .text_image_teaser .image {
    margin-bottom: -2.8125rem;
    position: relative;
    z-index: 50;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  .checkbox .tooltip_wrapper {
    position: static;
  }
  [role="tooltip"] {
    background: #f2f2f2;
    border: 0.0625rem solid #d9d9d9;
    border-radius: 0.125rem;
    color: #000000;
    font-size: 85%;
    font-weight: 300;
    line-height: 2.0625rem;
    padding: 0 0.6em;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #f2f2f2;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #d9d9d9;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .triple_text_overview .triple_text {
    margin: 3.6em 0;
  }
  .triple_text_overview .triple_text:first-child {
    margin-top: 0;
  }
  .triple_text_overview .triple_text:last-child {
    margin-bottom: 0;
  }
  .triple_text_overview .triple_text h2 {
    font-weight: 600;
    margin-bottom: 0.9375rem;
    text-transform: uppercase;
  }
  .triple_text_overview .triple_text h2 .title_addition {
    color: #ffed00;
    font-weight: 300;
  }
  .triple_text_overview .triple_text .description {
    letter-spacing: calc((0.9375rem + 2.0625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.9375rem;
  }
  .triple_text_overview.leistungen .title {
    letter-spacing: calc((1.25rem + 1.75
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 1.25rem;
    font-weight: 200;
    text-transform: none;
  }
  .triple_text_overview.leistungen .title .title_addition {
    color: #000000;
    font-weight: 600;
    text-transform: uppercase;
  }
  .triple_text_overview.leistungen .description {
    letter-spacing: calc((0.9375rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.9375rem;
  }
  .triple_text_overview.air h2,
  .triple_text_overview.mta h2,
  .triple_text_overview.ekon h2,
  .triple_text_overview.mykron h2,
  .triple_text_overview.plenon h2,
  .triple_text_overview.zeron h2 {
    text-transform: none;
  }
  .triple_text_overview.air .title,
  .triple_text_overview.mta .title,
  .triple_text_overview.ekon .title,
  .triple_text_overview.mykron .title,
  .triple_text_overview.plenon .title,
  .triple_text_overview.zeron .title {
    letter-spacing: calc((1.25rem + 1.75
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 1.25rem;
    font-weight: 200;
  }
  .triple_text_overview.air .description,
  .triple_text_overview.mta .description,
  .triple_text_overview.ekon .description,
  .triple_text_overview.mykron .description,
  .triple_text_overview.plenon .description,
  .triple_text_overview.zeron .description {
    letter-spacing: calc((0.9375rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 30);
    font-size: 0.9375rem;
  }
  .triple_text_overview.mykron h2 .title_addition,
  .triple_text_overview.ekon h2 .title_addition {
    color: #000000;
    font-weight: 600;
  }
  .your_entry_jobs_teaser_overview {
    margin: auto;
    margin-bottom: 1.2em;
    margin-top: 1.2em;
    max-width: 70vw;
  }
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser {
    min-height: 15.625rem;
    padding-bottom: 1.2em;
    padding-left: 1.2em;
    padding-right: 1.8em;
    padding-top: 1.2em;
    background-color: #000000;
    border: 0.0625rem solid #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 1.2em;
  }
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser .title {
    letter-spacing: calc((1.25rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.25rem;
    color: #fff;
    font-weight: 200;
  }
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser .title_addition {
    letter-spacing: calc((1.875rem + 1.875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.875rem;
    color: #ffed00;
    font-weight: 600;
  }
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser a .text {
    color: #ffed00;
    letter-spacing: calc((1rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1rem;
  }
}
@media screen and (min-width: 20rem) {
  [role="tab"] .title {
    font-size: calc(1rem + 0.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [role="tab"] .title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 20rem) {
  [role="tab"] .title::after {
    width: calc(6.25rem + 12.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [role="tab"] .title::after {
    width: 18.75rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .aqi-externaldata-content {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
  }
}
@media screen and (min-width: 61.875rem) {
  .aqi-externaldata-content .aqi-externaldata-text {
    font-size: 120%;
  }
}
@media screen and (min-width: 35rem) {
  .anchor_headline {
    margin: 3.75rem 0;
    padding: 6.875rem 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .anchor_headline {
    margin: 5rem 0;
    padding: 11.875rem 0;
  }
}
@media screen and (min-width: 20rem) {
  .anchor_headline .title {
    font-size: calc(1.125rem + 0.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .anchor_headline .title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .anchor_headline .title {
    max-width: 69%;
  }
}
@media screen and (min-width: 61.875rem) {
  .anchor_headline .title {
    max-width: 50%;
  }
}
@media screen and (min-width: 93.75rem) {
  .anchor_headline .title {
    max-width: 45%;
  }
}
@media screen and (min-width: 47.5rem) {
  .anchor_headline .icon {
    height: 2.125rem;
    width: 2.125rem;
  }
}
@media screen and (min-width: 30rem) {
  .background_image.game_changer_image {
    min-height: 18.75rem;
  }
}
@media screen and (min-width: 35rem) {
  .background_image.game_changer_image {
    min-height: 25rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .background_image.game_changer_image {
    margin-left: 0;
    min-height: 18.75rem;
  }
}
@media screen and (min-width: 75rem) {
  .background_image.game_changer_image {
    margin-right: -4%;
    min-height: 22.5rem;
    width: auto;
  }
}
@media screen and (min-width: 93.75rem) {
  .background_image.game_changer_image {
    min-height: 38.75rem;
  }
}
@media screen and (min-width: 75rem) {
  .background_image_overview {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-left: 0;
    margin-right: -4%;
  }
  .blog_page .background_image_overview {
    margin-right: 0;
  }
}
@media screen and (min-width: 20rem) {
  .background_image_overview .slider_headline p,
  .background_image_overview .slider_headline h2,
  .background_image_overview .slider_headline h3,
  .background_image_overview .slider_headline h4 {
    font-size: calc(0.875rem + 2.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .background_image_overview .slider_headline p,
  .background_image_overview .slider_headline h2,
  .background_image_overview .slider_headline h3,
  .background_image_overview .slider_headline h4 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .background_image_overview .navigation_wrapper {
    bottom: calc(0.625rem + 1.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .background_image_overview .navigation_wrapper {
    bottom: 1.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .background_image_overview .navigation_wrapper {
    padding: 0 calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .background_image_overview .navigation_wrapper {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .background_image_overview .navigation_wrapper .navigation {
    height: calc(3rem + 0.5625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .background_image_overview .navigation_wrapper .navigation {
    height: 3.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .background_image_overview .navigation_wrapper .navigation {
    width: calc(3rem + 0.5625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .background_image_overview .navigation_wrapper .navigation {
    width: 3.5625rem;
  }
}
@media screen and (min-width: 35rem) {
  .infobanner {
    width: 60%;
  }
}
@media screen and (min-width: 61.875rem) {
  .infobanner {
    max-width: 35rem;
    width: 50vw;
  }
}
@media screen and (max-height: 56.25rem) {
  .infobanner {
    max-height: 31.25rem;
    max-width: 31.25rem;
  }
}
@media screen and (max-height: 43.75rem) {
  .infobanner {
    max-height: 25rem;
    max-width: 25rem;
  }
}
@media screen and (max-height: 37.5rem) {
  .infobanner {
    max-height: 18.75rem;
    max-width: 18.75rem;
  }
}
@media screen and (max-height: 25rem) {
  .infobanner {
    max-height: 12.5rem;
    max-width: 12.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .blog_container {
    padding: 0;
  }
}
@media screen and (min-width: 93.75rem) {
  .blog_container {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .blog_article_overview.big_title .blog_entry .title_wrapper p {
    font-size: calc(1rem + 2
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .blog_article_overview.big_title .blog_entry .title_wrapper p {
    font-size: 3rem;
  }
}
@media screen and (min-width: 75rem) {
  .blog_article_overview .row {
    max-width: 80%;
    margin: 1.2em auto 0;
  }
}
@media screen and (min-width: 20rem) {
  .blog_article_overview .blog_entry .title_wrapper p {
    font-size: calc(1rem + 0.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .blog_article_overview .blog_entry .title_wrapper p {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .blog_call_to_action .title h2 {
    font-size: calc(0.875rem + 2.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .blog_call_to_action .title h2 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .blog_call_to_action .cta_link .text span {
    font-size: calc(0.875rem + 2.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .blog_call_to_action .cta_link .text span {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .button {
    font-size: calc(0.9375rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button {
    font-size: 1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active,
  .button.active {
    font-size: calc(0.9375rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button:active,
  .button.active {
    font-size: 1.5625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .call_to_action .content {
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .call_to_action .content::after {
    background: #000000;
    content: "";
    left: -62.5rem;
    position: absolute;
    right: -62.5rem;
    top: 0;
    z-index: -1;
    height: 7.5rem;
  }
  .call_to_action .content::before {
    background: #ffed00;
    content: "";
    left: -62.5rem;
    position: absolute;
    right: -62.5rem;
    bottom: 0;
    z-index: -1;
    height: 7.5rem;
  }
}
@media screen and (min-width: 47.5rem) and (min-width: 20rem) {
  .call_to_action .content::after {
    height: calc(7.5rem + 10
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 47.5rem) and (min-width: 93.75rem) {
  .call_to_action .content::after {
    height: 17.5rem;
  }
}
@media screen and (min-width: 47.5rem) and (min-width: 20rem) {
  .call_to_action .content::before {
    height: calc(7.5rem + 10
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 47.5rem) and (min-width: 93.75rem) {
  .call_to_action .content::before {
    height: 17.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .call_to_action .cta_link {
    font-size: calc(1.5rem + 0.6875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .call_to_action .cta_link {
    font-size: 2.1875rem;
  }
}
@media screen and (max-width: 47.4375rem) {
  .call_to_action .cta_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
            justify-content: flex-start;
    position: relative;
  }
  .call_to_action .cta_link::after {
    background: #ffed00;
    bottom: 0;
    content: "";
    left: -62.5rem;
    position: absolute;
    right: -62.5rem;
    top: 0;
    z-index: -1;
  }
  .call_to_action .cta_link:nth-child(2)::after {
    background: #000000;
  }
  .call_to_action .cta_link:nth-child(2) .icon_wrapper .icon {
    fill: #ffed00;
  }
  .call_to_action .cta_link:nth-child(2) .text {
    color: #ffed00;
  }
}
@media screen and (min-width: 47.5rem) {
  .call_to_action .cta_link {
    width: 32%;
    font-size: 1.625rem;
  }
}
@media screen and (min-width: 75rem) {
  .call_to_action .cta_link {
    width: 25%;
  }
}
@media screen and (min-width: 93.75rem) {
  .call_to_action .cta_link {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 20rem) {
  .call_to_action .cta_link .icon_wrapper {
    height: calc(5rem + 12.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .call_to_action .cta_link .icon_wrapper {
    height: 17.5rem;
  }
}
@media screen and (max-width: 47.4375rem) {
  .call_to_action .cta_link .icon_wrapper {
    width: 5rem;
  }
  .call_to_action .cta_link .icon_wrapper .icon {
    fill: #000000;
  }
}
@media screen and (max-width: 47.4375rem) and (min-width: 20rem) {
  .call_to_action .cta_link .icon_wrapper {
    width: calc(5rem + 12.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (max-width: 47.4375rem) and (min-width: 93.75rem) {
  .call_to_action .cta_link .icon_wrapper {
    width: 17.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .call_to_action .cta_link .icon {
    height: calc(2.5rem + 6.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .call_to_action .cta_link .icon {
    height: 9.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .call_to_action .cta_link .icon {
    width: calc(2.5rem + 6.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .call_to_action .cta_link .icon {
    width: 9.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .call_to_action .cta_link .text_wrapper {
    height: 7.5rem;
  }
}
@media screen and (min-width: 47.5rem) and (min-width: 20rem) {
  .call_to_action .cta_link .text_wrapper {
    height: calc(7.5rem + 10
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 47.5rem) and (min-width: 93.75rem) {
  .call_to_action .cta_link .text_wrapper {
    height: 17.5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .call_to_action .image {
    max-width: 12.5rem;
    margin-bottom: -4.8em;
  }
}
@media screen and (min-width: 75rem) {
  .call_to_action .image {
    max-width: 20rem;
    bottom: auto;
    top: -6.25rem;
    margin-bottom: auto;
  }
}
@media screen and (min-width: 61.875rem) {
  .certificate .image .bg_image {
    left: 0;
  }
}
@media screen and (min-width: 20rem) {
  .certificate .content {
    font-size: calc(0.9375rem + 0.9375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .certificate .content {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    max-width: calc(9.375rem + 1.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    max-width: 10.75rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .company_logo {
    margin: 0.875rem 3.75rem;
  }
}
@media screen and (min-width: 75rem) {
  .company_logo {
    margin: 2.1875rem 5.9375rem;
    min-width: 10.75rem;
  }
}
@media screen and (max-width: 61.8125rem) {
  .company_logo_addition {
    width: 17.5rem;
    max-width: 17.5rem;
  }
}
@media screen and (max-width: 61.8125rem) {
  .company_logo_addition {
    width: 12.5rem;
    max-width: 12.5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .company_logo_addition > div {
    display: block;
    height: 4.5625rem;
  }
  .company_logo_addition .img_resize {
    margin-top: -0.625rem;
    padding-bottom: 33%;
  }
}
@media screen and (min-width: 61.875rem) {
  .company_logo_addition {
    max-width: 15.625rem;
    width: 29.0625rem;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 20rem) {
  .company_logo_addition {
    max-width: calc(15.625rem + 13.4375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 93.75rem) {
  .company_logo_addition {
    max-width: 29.0625rem;
  }
}
@media screen and (max-width: 34.9375rem) {
  .content_slider.full_pagination .navigation_wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: center;
            align-items: center;
  }
}
@media screen and (max-width: 34.9375rem) {
  .content_slider.full_pagination .dot:before {
    content: none;
  }
}
@media screen and (min-width: 20rem) {
  .download_overview .touch_link h3.title_wrapper .title {
    font-size: calc(0.9375rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .download_overview .touch_link h3.title_wrapper .title {
    font-size: 1.1875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .download_overview_expanded .expanded_wrapper .text_wrapper {
    margin: 1.875rem 0 0 1.5625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .download_overview_expanded .expanded_wrapper svg {
    height: 2.8125rem;
    width: 9.4375rem;
  }
}
@media screen and (min-width: 20rem) {
  .download_overview_expanded .expanded_wrapper .title {
    font-size: calc(1.5625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .download_overview_expanded .expanded_wrapper .title {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 20rem) {
  .download_overview_expanded .expanded_wrapper .description {
    font-size: calc(1.125rem + 0.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .download_overview_expanded .expanded_wrapper .description {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .download_overview_expanded .expanded_wrapper .button {
    font-size: calc(1.5625rem + 0.3125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .download_overview_expanded .expanded_wrapper .button {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .erfassungssysteme .grey_wrapper {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}
@media screen and (min-width: 47.5rem) {
  .erfassungssysteme .black_wrapper {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 15.625rem;
            column-gap: 15.625rem;
    padding: 2.8125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .erfassungssysteme .black_wrapper .description {
    max-height: 22.1875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .erfassungssysteme .black_wrapper.one_span .description {
    max-width: 40%;
  }
}
@media screen and (min-width: 20rem) {
  footer .footer_wrapper {
    font-size: calc(0.8125rem + 0.3125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  footer .footer_wrapper {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .footer_wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    padding: 0.6em 0;
    z-index: 20;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .footer_wrapper .related_links ul {
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .footer_wrapper .related_links a {
    margin-left: 0.6em;
  }
}
@media screen and (min-width: 61.875rem) {
  .go_to_top {
    right: 1.2em;
  }
}
@media screen and (min-width: 61.875rem) {
  .go_to_top img {
    height: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button {
    font-size: calc(0.9375rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_button {
    font-size: 1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"]:active ~ .file_button {
    font-size: calc(0.9375rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"]:active ~ .file_button {
    font-size: 1.5625rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper button {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_radio {
    font-size: calc(0.9375rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_radio {
    font-size: 1.5625rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.double_column_margin {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 47.5rem) {
  .row.double_column_margin {
    margin: 0 -6.25rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.double_column_margin {
    margin: 0 -10.9375rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.double_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 47.5rem) {
  .row.double_column_margin > [class*="span"] {
    padding: 0 6.25rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.double_column_margin > [class*="span"] {
    padding: 0 10.9375rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.reverse_xlarge {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (max-width: 61.8125rem) {
  header {
    padding-top: 7.75rem;
    background-color: black;
  }
}
@media screen and (max-width: 61.8125rem) {
  .top_header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }
  .top_header.hide {
    opacity: 0;
    -webkit-transition: opacity 900ms;
    transition: opacity 900ms;
  }
}
@media screen and (min-width: 61.875rem) {
  .top_header {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
  .top_header.scrolled_down .logo_wrapper {
    background: rgba(255, 255, 255, 0.8);
    -webkit-transition: background 300ms, padding 300ms;
    transition: background 300ms, padding 300ms;
  }
  .top_header.scrolled_down .logo_wrapper:before {
    background: rgba(255, 255, 255, 0.8);
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .top_header.scrolled_down .logo_addition_wrapper,
  .top_header.scrolled_down .menu_wrapper {
    background: rgba(0, 0, 0, 0.8);
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .top_header.scrolled_down .menu_wrapper:after {
    background: rgba(0, 0, 0, 0.8);
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .top_header.scrolled_down .menu_wrapper {
    padding: 0.875rem 1.8125rem;
    -webkit-transition: padding 300ms;
    transition: padding 300ms;
  }
  .top_header.scrolled_down .menu_wrapper .menu {
    height: auto;
  }
  .top_header.scrolled_down .company_logo_addition {
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 21.875rem;
  }
  .top_header .company_logo {
    margin: 0.875rem 3.75rem;
    -webkit-transition: margin 300ms ease;
    transition: margin 300ms ease;
  }
  .top_header .company_logo_addition > div {
    display: block;
    height: 4.5625rem;
  }
  .top_header .company_logo_addition .img_resize {
    margin-top: -0.625rem;
    padding-bottom: 33%;
  }
  .top_header .logo_wrapper {
    background: rgba(255, 255, 255, 0.8);
    -webkit-transition: background 300ms, padding 300ms;
    transition: background 300ms, padding 300ms;
  }
  .top_header .logo_wrapper:before {
    background: rgba(255, 255, 255, 0.8);
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .top_header .logo_addition_wrapper,
  .top_header .menu_wrapper {
    background: rgba(0, 0, 0, 0.8);
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .top_header .menu_wrapper:after {
    background: rgba(0, 0, 0, 0.8);
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .top_header .menu_wrapper {
    padding: 0.875rem 1.8125rem;
    -webkit-transition: padding 300ms;
    transition: padding 300ms;
  }
  .top_header .menu_wrapper .menu {
    height: auto;
  }
  .top_header .company_logo_addition {
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 21.875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .top_header .top_header_wrapper {
    -webkit-box-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  .logo_header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (max-width: 61.8125rem) {
  .logo_header .logo_wrapper:after {
    background: #fff;
    bottom: 0;
    content: "";
    left: 100%;
    position: absolute;
    right: -62.5rem;
    top: 0;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
}
@media screen and (min-width: 61.875rem) {
  .menu_wrapper {
    padding: 1.125rem 1.8125rem;
  }
}
@media screen and (max-width: 61.8125rem) {
  .menu_wrapper:before {
    left: -62.5rem;
    right: 100%;
  }
}
@media screen and (min-width: 61.875rem) {
  .menu_wrapper .menu {
    height: 100%;
  }
}
@media screen and (max-width: 61.8125rem) {
  .menu_wrapper .test_wrapper {
    margin-top: 0.8125rem;
    position: absolute;
    right: 0.75rem;
    top: 0;
    z-index: 999;
  }
}
@media screen and (min-width: 61.875rem) {
  .menu_wrapper .test_wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    min-height: 3.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .contact_link {
    margin-right: 0.875rem;
  }
}
@media screen and (min-width: 75rem) {
  .contact_link {
    margin-right: 2.5rem;
  }
}
@media screen and (max-width: 29.9375rem) {
  .contact_link {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .contact_link:hover .icon svg {
    fill: #fff;
  }
}
@media screen and (min-width: 61.875rem) {
  .contact_link .icon svg {
    fill: #ffed00;
  }
}
@media screen and (min-width: 61.875rem) {
  .contact_link .icon svg {
    height: 3.125rem;
    width: 3.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .contact_link .text {
    color: #ffed00;
  }
}
@media screen and (min-width: 61.875rem) {
  .onlineshop_link {
    margin-right: 0.875rem;
  }
}
@media screen and (min-width: 75rem) {
  .onlineshop_link {
    margin-right: 2.5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .onlineshop_link .icon svg {
    height: 3.125rem;
    width: 3.125rem;
  }
}
@media screen and (min-width: 75rem) {
  .bg_outer_wrapper {
    max-height: 100vh;
    overflow: hidden;
  }
  .blog_overview_page .bg_outer_wrapper {
    max-height: 80vh;
  }
}
@media screen and (min-width: 47.5rem) {
  .bg_outer_wrapper .sitetitle_wrapper .sitetitle {
    height: 20vh;
  }
}
@media screen and (min-width: 61.875rem) {
  .bg_outer_wrapper .sitetitle_wrapper .sitetitle {
    height: 35vh;
  }
}
@media screen and (min-width: 61.875rem) {
  .bg_outer_wrapper .header_content_wrapper {
    bottom: 4.375rem;
  }
}
@media screen and (min-width: 75rem) {
  .bg_outer_wrapper .header_content_wrapper {
    bottom: 7.1875rem;
  }
}
@media screen and (min-width: 20rem) {
  .bg_outer_wrapper .header_content_wrapper p,
  .bg_outer_wrapper .header_content_wrapper h1 {
    font-size: calc(1.375rem + 1.3125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .bg_outer_wrapper .header_content_wrapper p,
  .bg_outer_wrapper .header_content_wrapper h1 {
    font-size: 2.6875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .bg_outer_wrapper .header_content_wrapper .arrow_down {
    height: 2.8125rem;
    width: 2.8125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .bg_outer_wrapper .header_content_wrapper .arrow_down .icon svg {
    height: 2.8125rem;
    width: 2.8125rem;
  }
}
@media screen and (min-width: 75rem) {
  .video_animated_header .bg_outer_wrapper {
    max-height: none;
  }
}
@media screen and (min-width: 75rem) {
  .bg_wrapper {
    height: 100vh;
    min-height: 43.75rem;
  }
  .blog_overview_page .bg_wrapper {
    height: 80vh;
    max-height: 80vh;
    min-height: auto;
  }
}
@media screen and (min-width: 47.5rem) {
  .image_content .arrow_down .icon {
    height: 2.8125rem;
    width: 2.8125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .image_content .arrow_down .icon svg {
    height: 2.8125rem;
    width: 2.8125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .sitetitle {
    margin: 0 auto;
    max-width: 60%;
  }
}
@media screen and (min-width: 93.75rem) {
  .blog_overview_page .header_background {
    padding-bottom: 0 !important;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_background {
    background-size: cover;
    padding-bottom: 50% !important;
  }
}
@media screen and (min-width: 20rem) {
  .header_headline_blog p {
    font-size: calc(0.875rem + 2.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_headline_blog p {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .header_slider .slide .slider_headline p {
    font-size: calc(0.875rem + 2.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_slider .slide .slider_headline p {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .image_gallery_entry .title {
    font-size: calc(0.9375rem + 0.9375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .image_gallery_entry .title {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 34.9375rem) {
  .image_gallery_entry .title {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .image_gallery_overlay .full_image figcaption {
    font-size: calc(1rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .image_gallery_overlay .full_image figcaption {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .infobanner_reachability {
    margin-top: 5.625rem;
    max-height: 70vh;
  }
}
@media screen and (min-width: 61.875rem) {
  .infobanner_reachability {
    padding: 2.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .infobanner_reachability {
    max-height: 80vh;
    padding: 3.75rem;
  }
}
@media screen and print {
  .infobanner_reachability {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .black_teaser_overview .black_teaser {
    min-height: calc(18.75rem + 21.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .black_teaser_overview .black_teaser {
    min-height: 40rem;
  }
}
@media screen and (min-width: 20rem) {
  .black_teaser_overview .black_teaser {
    padding: calc(1.7999999999999998em + 1.7999999999999998
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .black_teaser_overview .black_teaser {
    padding: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .black_teaser_overview .black_teaser .title {
    font-size: calc(1.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .black_teaser_overview .black_teaser .title {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 47.4375rem) {
  .black_teaser_overview .row > [class*="span"] .black_teaser {
    border-right: 0;
  }
}
@media screen and (max-width: 61.8125rem) {
  .black_teaser_overview .row > [class*="span"]:nth-child(even) .black_teaser {
    border-right: 0;
  }
}
@media screen and (min-width: 93.75rem) {
  .black_teaser_overview .row > [class*="span"]:nth-child(3) .black_teaser {
    border-right: 0;
  }
}
@media screen and (min-width: 20rem) {
  .jobs_and_career .padding_bottom,
  .bachelor .padding_bottom,
  .trainee .padding_bottom {
    padding-bottom: calc(1.875rem + 3.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .jobs_and_career .padding_bottom,
  .bachelor .padding_bottom,
  .trainee .padding_bottom {
    padding-bottom: 5.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .jobs_and_career .section,
  .bachelor .section,
  .trainee .section {
    padding-bottom: calc(1.875rem + 7.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .jobs_and_career .section,
  .bachelor .section,
  .trainee .section {
    padding-bottom: 9.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .jobs_and_career .section,
  .bachelor .section,
  .trainee .section {
    padding-top: calc(1.875rem + 7.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .jobs_and_career .section,
  .bachelor .section,
  .trainee .section {
    padding-top: 9.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .big_textpart {
    font-size: calc(1.125rem + 2.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .big_textpart {
    font-size: 3.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .backlink {
    margin-top: calc(2.5rem + 7.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .backlink {
    margin-top: 10rem;
  }
}
@media screen and (min-width: 20rem) {
  .font_family_senior {
    font-size: calc(1.75rem + 4.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .font_family_senior {
    font-size: 6.25rem;
  }
}
@media screen and (max-width: 61.8125rem) {
  .jobs_and_career_sitetitle {
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 61.875rem) {
  .jobs_and_career_sitetitle {
    padding: 2.5rem 0;
  }
}
@media screen and (min-width: 20rem) {
  .jobs_and_career_sitetitle .title {
    font-size: calc(1.125rem + 0.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .jobs_and_career_sitetitle .title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .jobs_and_career_sitetitle .title {
    max-width: 69%;
  }
}
@media screen and (min-width: 61.875rem) {
  .jobs_and_career_sitetitle .title {
    max-width: 50%;
  }
}
@media screen and (min-width: 93.75rem) {
  .jobs_and_career_sitetitle .title {
    max-width: 45%;
  }
}
@media screen and (min-width: 47.5rem) {
  .jobs_and_career_sitetitle .icon {
    height: 2.125rem;
    width: 2.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .jobs_and_career_sitetitle.team_entry_sitetitle .title_wrapper {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
}
@media screen and (min-width: 47.5rem) {
  .jobs_and_career_sitetitle.team_entry_sitetitle .title_wrapper .icon svg {
    height: 2.125rem;
    width: 2.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .jobs_and_career_sitetitle.team_entry_sitetitle h1,
  .jobs_and_career_sitetitle.team_entry_sitetitle .like_h1 {
    font-size: calc(1.375rem + 6.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .jobs_and_career_sitetitle.team_entry_sitetitle h1,
  .jobs_and_career_sitetitle.team_entry_sitetitle .like_h1 {
    font-size: 7.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .yellow_text_area {
    font-size: calc(1.375rem + 1.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .yellow_text_area {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .yellow_text_area a {
    font-size: calc(1rem + 1.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .yellow_text_area a {
    font-size: 2.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .yellow_text_area a.online {
    font-size: calc(1.375rem + 1.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .yellow_text_area a.online {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .application_process_text {
    font-size: calc(1.125rem + 0.4375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .application_process_text {
    font-size: 1.5625rem;
  }
}
@media screen and (max-width: 47.4375rem) {
  .strong_medium.section {
    padding-bottom: 1.2em;
  }
}
@media screen and (min-width: 20rem) {
  .headline_with_arrow .title {
    font-size: calc(1.25rem + 1.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .headline_with_arrow .title {
    font-size: 2.625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .headline_with_arrow .icon {
    height: 2.125rem;
    width: 2.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .check_in_zero_emisson_text {
    margin-top: calc(1.2em + 17.55
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .check_in_zero_emisson_text {
    margin-top: 18.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .check_in_zero_emisson_text {
    margin-bottom: calc(1.2em + 8.8
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .check_in_zero_emisson_text {
    margin-bottom: 10rem;
  }
}
@media screen and (min-width: 20rem) {
  .trainee_headline p {
    font-size: calc(1rem + 1.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .trainee_headline p {
    font-size: 2.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .trainee_headline .title {
    font-size: calc(1.25rem + 1.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .trainee_headline .title {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .title_headline {
    margin-bottom: calc(1.2em + 8.175
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .title_headline {
    margin-bottom: 9.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .textelement_overview .textelement.font_family_senior_title .title {
    font-size: calc(1.75rem + 4.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .textelement_overview .textelement.font_family_senior_title .title {
    font-size: 6.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .textelement_overview .textelement.font_family_senior_title .title_addition {
    font-size: calc(0.9375rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .textelement_overview .textelement.font_family_senior_title .title_addition {
    font-size: 1.1875rem;
  }
}
@media screen and (min-width: 20rem) {
  .textelement_overview .textelement.big_text {
    font-size: calc(0.9375rem + 0.9375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .textelement_overview .textelement.big_text {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 61.8125rem) {
  .textelement_overview .textelement .entry.padding_top {
    padding-top: 2.4em;
  }
}
@media screen and (min-width: 20rem) {
  .textelement_overview.jobs_startpage_text .title {
    font-size: calc(1.875rem + 1.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .textelement_overview.jobs_startpage_text .title {
    font-size: 3.75rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .job_nav_overview.your_entry .entry {
    min-height: 25rem;
  }
}
@media screen and (min-width: 20rem) {
  .job_nav_overview .entry .title {
    font-size: calc(1.5625rem + 0.3125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .job_nav_overview .entry .title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 75rem) {
  .job_nav_overview .entry .title {
    max-width: 21.875rem;
  }
}
@media screen and (max-width: 61.8125rem) {
  .job_nav_overview .row > [class*="span"] .entry {
    border-left: 0;
    border-right: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .job_nav_overview .row > [class*="span"]:nth-child(-n + 3) .entry {
    border-top: 0;
  }
  .job_nav_overview .row > [class*="span"]:nth-child(n + 3) .entry {
    border-bottom: 0;
  }
  .job_nav_overview .row > [class*="span"]:nth-child(3n + 1) .entry {
    border-left: 0;
  }
  .job_nav_overview .row > [class*="span"]:nth-child(3n + 3) .entry {
    border-right: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .phase_teaser {
    margin: 6.25rem 0;
  }
}
@media screen and (min-width: 93.75rem) {
  .phase_teaser .phase_teaser_element {
    max-width: 85%;
  }
}
@media screen and (min-width: 20rem) {
  .phase_teaser .phase_teaser_element .title {
    font-size: calc(1.25rem + 1.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .phase_teaser .phase_teaser_element .title {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .phase_teaser .phase_teaser_element .description {
    font-size: calc(1.125rem + 0.4375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .phase_teaser .phase_teaser_element .description {
    font-size: 1.5625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .phase_teaser .phase_teaser_element .description {
    padding: 1.5625rem 7.5rem 9.375rem 1.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .phase_teaser .phase_teaser_element .icon {
    right: -3.75rem;
  }
}
@media screen and (min-width: 75rem) {
  .phase_teaser .phase_teaser_element .icon {
    opacity: 0;
    -webkit-transform: translateY(-50%) translateX(2.5rem) scale(0.8);
            transform: translateY(-50%) translateX(2.5rem) scale(0.8);
  }
}
@media screen and (min-width: 47.5rem) {
  .phase_teaser .phase_teaser_element .icon svg {
    height: 10rem;
    width: 10rem;
  }
}
@media screen and (min-width: 75rem) {
  .phase_teaser.animated .icon {
    opacity: 1;
    -webkit-transform: translateY(-50%) translateX(0) scale(1);
            transform: translateY(-50%) translateX(0) scale(1);
    -webkit-transition: all 900ms ease;
    transition: all 900ms ease;
  }
  .phase_teaser.animated .phase_teaser_element_2 .icon {
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
  }
  .phase_teaser.animated .phase_teaser_element_3 .icon {
    -webkit-transition-delay: 0.6s;
            transition-delay: 0.6s;
  }
}
@media screen and (max-width: 61.8125rem) {
  .job_overview_text.section {
    padding: 0 0 2.4em 0;
  }
}
@media screen and (max-width: 61.8125rem) {
  .job_overview_text .padding_top {
    padding-top: 2.4em;
  }
}
@media screen and (min-width: 47.5rem) {
  .job_teaser_overview .row {
    padding-top: calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .job_teaser_overview .row {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .job_teaser_overview .entry {
    margin-top: calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 47.5rem) {
  .job_teaser_overview .entry {
    margin-top: 0;
  }
}
@media screen and (min-width: 75rem) {
  .job_teaser_overview .entry {
    max-width: 30rem;
  }
}
@media screen and (min-width: 20rem) {
  .job_teaser_overview .entry .content {
    padding: calc(0.3em + 0.8999999999999999
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .job_teaser_overview .entry .content {
    padding: 1.2em;
  }
}
@media screen and (min-width: 75rem) {
  .job_teaser_overview .entry .content .title {
    letter-spacing: calc((0.75rem + 1
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 75rem) and (min-width: 20rem) {
  .job_teaser_overview .entry .content .title {
    font-size: calc(0.75rem + 1
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 75rem) and (min-width: 93.75rem) {
  .job_teaser_overview .entry .content .title {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 75rem) {
  .job_teaser_overview .entry .content .description {
    letter-spacing: calc((0.75rem + 1
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 75rem) and (min-width: 20rem) {
  .job_teaser_overview .entry .content .description {
    font-size: calc(0.75rem + 1
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 75rem) and (min-width: 93.75rem) {
  .job_teaser_overview .entry .content .description {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .job_teaser {
    padding-left: 3.125rem !important;
  }
}
@media screen and (min-width: 20rem) {
  .job_teaser .job_title {
    font-size: calc(1.5625rem + 3.4375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .job_teaser .job_title {
    font-size: 5rem;
  }
}
@media screen and (min-width: 20rem) {
  .job_teaser .additional_title {
    font-size: calc(1.125rem + 1.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .job_teaser .additional_title {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .job_teaser .content_wrapper {
    margin-top: calc(3.125rem + 7.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .job_teaser .content_wrapper {
    margin-top: 10.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .job_teaser .goal {
    font-size: calc(1.25rem + 1.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .job_teaser .goal {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .job_teaser .goal .title {
    font-size: calc(2.5rem + 2.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .job_teaser .goal .title {
    font-size: 5rem;
  }
}
@media screen and (min-width: 20rem) {
  .job_teaser .conditions {
    font-size: calc(1.125rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .job_teaser .conditions {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .job_teaser .conditions {
    margin-top: 25rem;
  }
}
@media screen and (min-width: 20rem) {
  .job_teaser .black_profile {
    font-size: calc(1rem + 0.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .job_teaser .black_profile {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .job_teaser .black_profile {
    padding: 3.75rem 7.5rem 3.75rem 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .job_teaser .photo_span .photo_wrapper {
    height: 100vh;
  }
  .job_teaser .photo_span .photo_wrapper .photo {
    position: absolute;
  }
  .job_teaser .photo_span .photo_wrapper .photo.fixed {
    position: fixed;
  }
}
@media screen and (min-width: 61.875rem) {
  .job_teaser .jobs_button .button {
    margin: 3.4375rem auto 0 3.4375rem;
  }
}
@media screen and (min-width: 20rem) {
  .trainerportrait {
    padding-bottom: calc(0.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .trainerportrait {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .trainerportrait {
    margin-bottom: 7.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .trainerportrait {
    margin-bottom: 11.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .trainerportrait .image_wrapper .text_overlay {
    font-size: calc(0.9375rem + 0.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .trainerportrait .image_wrapper .text_overlay {
    font-size: 1.8125rem;
  }
}
@media screen and (min-width: 35rem) {
  .trainerportrait .image_wrapper .text_overlay {
    bottom: 2.5rem;
    left: 2.5rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .trainerportrait .image_wrapper .text_overlay {
    bottom: 5rem;
  }
}
@media screen and (min-width: 20rem) {
  .trainerportrait .image_wrapper .text_overlay strong {
    font-size: calc(1.375rem + 7.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .trainerportrait .image_wrapper .text_overlay strong {
    font-size: 8.75rem;
  }
}
@media screen and (max-width: 61.8125rem) {
  .trainerportrait .image_wrapper .text_overlay strong {
    font-size: 4.375rem;
  }
}
@media screen and (max-width: 34.9375rem) {
  .trainerportrait .image_wrapper .text_overlay strong {
    font-size: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .trainerportrait .description {
    font-size: calc(0.9375rem + 0.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .trainerportrait .description {
    font-size: 1.8125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .trainerportrait .description {
    margin: 1.25rem 0 3.125rem auto;
  }
}
@media screen and (min-width: 61.875rem) {
  .trainerportrait .description {
    margin: auto 0 8.75rem auto;
  }
}
@media screen and (max-width: 47.4375rem) {
  .kundenstimme .kundenstimme_background {
    padding-bottom: 60%;
  }
}
@media screen and (max-width: 47.4375rem) {
  .kundenstimme .content .headline.margin_bottom {
    margin-bottom: 2.4em;
  }
}
@media screen and (min-width: 47.5rem) {
  .kundenstimme .content .headline {
    margin-left: -2.4em;
  }
}
@media screen and (min-width: 20rem) {
  .kundenstimme .content .headline h3 {
    font-size: calc(1.25rem + 1.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .kundenstimme .content .headline h3 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 35rem) {
  .language_select ul {
    margin-right: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .language_select ul li {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .language_select ul li {
    font-size: 1rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .language_select ul li:first-child:after {
    color: #fff;
  }
}
@media screen and (min-width: 61.875rem) {
  .language_select ul a {
    color: #fff;
  }
}
@media screen and (min-width: 75rem) {
  .grey_bow:after {
    padding-bottom: 60%;
    left: -11%;
    right: -11%;
  }
}
@media screen and (min-width: 20rem) {
  .styled_like_h1 {
    font-size: calc(1.375rem + 1.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .styled_like_h1 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 61.8125rem) {
  .shortlinks .fixed_wrapper {
    top: 0 !important;
  }
}
@media screen and (min-width: 61.875rem) {
  .shortlinks .fixed_wrapper.fixed {
    position: fixed;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
}
@media screen and (min-width: 35rem) {
  .icon_headline {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-align: center;
            align-items: center;
  }
}
@media screen and (max-width: 34.9375rem) {
  .icon_headline img {
    margin-bottom: 1.2em;
  }
}
@media screen and (min-width: 75rem) {
  .excess:before {
    left: -2%;
  }
  .excess:after {
    right: -2%;
  }
}
@media screen and (min-width: 20rem) {
  .section_picture:before {
    left: calc(1.25rem + 3.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .section_picture:before {
    left: 5rem;
  }
}
@media screen and (min-width: 20rem) {
  .section_big {
    padding-top: calc(3.125rem + 14.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .section_big {
    padding-top: 17.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .section_big {
    padding-bottom: calc(3.125rem + 14.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .section_big {
    padding-bottom: 17.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .section {
    padding: 6.25rem 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .section {
    padding: 9.1875rem 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .section_small {
    padding: 3.75rem 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .section_small {
    padding: 5.625rem 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .grey_section_small {
    padding: 1.875rem 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .grey_section_small {
    padding: 3.75rem 0;
  }
}
@media screen and (max-width: 47.4375rem) {
  .row_section_small {
    padding: 2.8125rem 0;
  }
}
@media screen and (max-width: 47.4375rem) {
  .margin_top_mobile {
    margin-top: 6em;
  }
}
@media screen and (min-width: 20rem) {
  .margin_top_double {
    margin-top: calc(5.625rem + 5.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .margin_top_double {
    margin-top: 11.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .margin_bottom_double {
    margin-bottom: calc(5.625rem + 5.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .margin_bottom_double {
    margin-bottom: 11.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .bigger_font {
    font-size: calc(1rem + 2.3125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .bigger_font {
    font-size: 3.3125rem;
  }
}
@media screen and (min-width: 20rem) {
  .startpage .section_1 .column_1 p {
    font-size: calc(2.1875rem + 1.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage .section_1 .column_1 p {
    font-size: 3.3125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .startpage .section_1 .column_1 p {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .startpage .two_columns {
    display: -webkit-box;
    display: flex;
  }
  .startpage .two_columns .two_columns_text {
    flex-basis: 50%;
  }
  .startpage .two_columns .two_columns_text:first-child {
    margin-right: 3.75rem;
  }
  .startpage .two_columns .two_columns_text:last-child {
    padding-top: 0rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .half_size_video {
    max-width: 50%;
  }
}
@media screen and (min-width: 61.875rem) {
  .video_wrapper .icon_wrapper {
    left: 5rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .video_wrapper .icon_wrapper {
    top: 82vh;
  }
}
@media screen and (min-width: 100.3125rem) {
  .video_wrapper .icon_wrapper {
    top: 90vh;
  }
}
@media screen and (min-width: 47.5rem) {
  .video_wrapper .icon_wrapper .icon svg {
    height: 4.375rem;
    width: 4.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .volume_button_wrapper .volume_button {
    height: calc(2.75rem + 6.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .volume_button_wrapper .volume_button {
    height: 9.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .volume_button_wrapper .volume_button {
    right: calc(0.625rem + 0.575
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .volume_button_wrapper .volume_button {
    right: 1.2em;
  }
}
@media screen and (min-width: 20rem) {
  .volume_button_wrapper .volume_button {
    top: calc(2.5rem + 3.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .volume_button_wrapper .volume_button {
    top: 6.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .volume_button_wrapper .volume_button {
    width: calc(2.75rem + 6.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .volume_button_wrapper .volume_button {
    width: 9.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .volume_button_wrapper .volume_button .icon {
    height: calc(2.5rem + 2.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .volume_button_wrapper .volume_button .icon {
    height: 5rem;
  }
}
@media screen and (min-width: 20rem) {
  .volume_button_wrapper .volume_button .icon {
    width: calc(2.5rem + 2.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .volume_button_wrapper .volume_button .icon {
    width: 5rem;
  }
}
@media screen and (min-width: 20rem) {
  .volume_button_wrapper .volume_button .icon svg {
    height: calc(1.25rem + 1.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .volume_button_wrapper .volume_button .icon svg {
    height: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .volume_button_wrapper .volume_button .icon svg {
    width: calc(1.25rem + 1.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .volume_button_wrapper .volume_button .icon svg {
    width: 2.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .seal {
    margin-top: -12.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .seal {
    margin-top: -15.625rem;
  }
}
@media screen and (max-width: 47.4375rem) {
  .toggle_offcanvas_menu {
    margin-bottom: 0.1875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .offcanvas_menu {
    bottom: 0;
    margin-top: 5.625rem;
    padding: 2.5rem;
  }
}
@media screen and (max-height: 37.5rem) {
  .offcanvas_menu {
    min-height: 0;
    bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 75rem) {
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > a {
    line-height: 2.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > ul a {
    font-size: calc(0.8125rem + 0.3125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > ul a {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .offcanvas_menu.black_offcanvas_menu .offcanvas_menu_content > ul > li > ul a {
    padding: 0.625rem 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .offcanvas_menu .offcanvas_menu_content {
    padding: 2.5rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu .offcanvas_menu_content {
    padding: 2.5rem 2.5rem 2.5rem 18.75rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: start;
            justify-content: flex-start;
    width: 25%;
  }
}
@media screen and (min-width: 47.5rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > ul {
    position: absolute;
    left: 30%;
    top: 0;
    width: 28vw;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    line-height: 2.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li.toggle_link {
    font-size: calc(0.8125rem + 0.3125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li.toggle_link {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li ul.jobs_sub_sub {
    margin-left: 0;
    max-width: 30vw;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(140%);
            transform: translateX(140%);
    max-width: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul > li ul li.expanded > ul {
    margin-left: 0;
    max-width: 30vw;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(140%);
            transform: translateX(140%);
    width: 80%;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul a {
    font-size: calc(0.8125rem + 0.3125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul a {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > ul a {
    padding: 0.625rem 0;
  }
}
@media screen and (min-width: 20rem) {
  .product_detail_overview .product_detail .title_wrapper .title {
    font-size: calc(0.9375rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .product_detail_overview .product_detail .title_wrapper .title {
    font-size: 1.1875rem;
  }
}
@media screen and (min-width: 75rem) {
  form[role="search"] {
    margin-right: 2.5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  form[role="search"] button .icon {
    fill: #fff;
  }
}
@media screen and (min-width: 61.875rem) {
  form[role="search"] button[disabled] .icon {
    fill: #fff;
  }
}
@media screen and (min-width: 61.875rem) {
  form[role="search"] button ~ input[type="search"] {
    width: 3.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  form[role="search"] button ~ input[type="search"] {
    width: 9.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  form[role="search"] button:focus .icon,
  form[role="search"] button:hover .icon {
    fill: #fff;
  }
}
@media screen and (min-width: 61.875rem) {
  form[role="search"]:focus input[type="search"],
  form[role="search"]:hover input[type="search"] {
    width: 3.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  form[role="search"]:focus input[type="search"],
  form[role="search"]:hover input[type="search"] {
    width: 9.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  form[role="search"] input[type="search"] {
    color: #fff;
  }
}
@media screen and (min-width: 61.875rem) {
  form[role="search"] input[type="search"]:focus {
    color: #fff;
  }
}
@media screen and (min-width: 30rem) {
  .slider_second,
  .slider_third,
  .slider {
    height: 17.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .slider_second,
  .slider_third,
  .slider {
    height: 25.625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .slider_second,
  .slider_third,
  .slider {
    height: 29.8125rem;
  }
}
@media screen and (min-width: 30rem) {
  .slider_second.big_pic_slider,
  .slider_third.big_pic_slider,
  .slider.big_pic_slider {
    height: 22.5rem;
  }
}
@media screen and (min-width: 35rem) {
  .slider_second.big_pic_slider,
  .slider_third.big_pic_slider,
  .slider.big_pic_slider {
    height: 35rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .slider_second.big_pic_slider,
  .slider_third.big_pic_slider,
  .slider.big_pic_slider {
    height: 50rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .slider_second.big_pic_slider,
  .slider_third.big_pic_slider,
  .slider.big_pic_slider {
    height: 31.25rem;
  }
}
@media screen and (min-width: 75rem) {
  .slider_second.big_pic_slider,
  .slider_third.big_pic_slider,
  .slider.big_pic_slider {
    height: 41.875rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .slider_second.big_pic_slider,
  .slider_third.big_pic_slider,
  .slider.big_pic_slider {
    height: 59.375rem;
  }
}
@media screen and (min-width: 35rem) {
  .slider_second.big_pic_slider .slide,
  .slider_third.big_pic_slider .slide,
  .slider.big_pic_slider .slide {
    max-width: 70%;
  }
}
@media screen and (min-width: 61.875rem) {
  .slider_second.big_pic_slider .slide,
  .slider_third.big_pic_slider .slide,
  .slider.big_pic_slider .slide {
    max-width: 40%;
  }
}
@media screen and (min-width: 35rem) {
  .slider_second .slide,
  .slider_third .slide,
  .slider .slide {
    padding: 0 3.4375rem;
  }
}
@media screen and (min-width: 35rem) {
  .slider_second .slide.prev,
  .slider_third .slide.prev,
  .slider .slide.prev {
    -webkit-transform: translateX(-85%);
            transform: translateX(-85%);
    visibility: visible;
  }
}
@media screen and (min-width: 47.5rem) {
  .slider_second .slide.prev,
  .slider_third .slide.prev,
  .slider .slide.prev {
    -webkit-transform: translateX(-90%);
            transform: translateX(-90%);
  }
}
@media screen and (min-width: 75rem) {
  .slider_second .slide.prev,
  .slider_third .slide.prev,
  .slider .slide.prev {
    -webkit-transform: translateX(-80%);
            transform: translateX(-80%);
  }
}
@media screen and (min-width: 93.75rem) {
  .slider_second .slide.prev,
  .slider_third .slide.prev,
  .slider .slide.prev {
    -webkit-transform: translateX(-70%);
            transform: translateX(-70%);
  }
}
@media screen and (min-width: 118.75rem) {
  .slider_second .slide.prev,
  .slider_third .slide.prev,
  .slider .slide.prev {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@media screen and (min-width: 35rem) {
  .slider_second .slide.next,
  .slider_third .slide.next,
  .slider .slide.next {
    -webkit-transform: translateX(85%);
            transform: translateX(85%);
    visibility: visible;
  }
}
@media screen and (min-width: 47.5rem) {
  .slider_second .slide.next,
  .slider_third .slide.next,
  .slider .slide.next {
    -webkit-transform: translateX(90%);
            transform: translateX(90%);
  }
}
@media screen and (min-width: 75rem) {
  .slider_second .slide.next,
  .slider_third .slide.next,
  .slider .slide.next {
    -webkit-transform: translateX(80%);
            transform: translateX(80%);
  }
}
@media screen and (min-width: 93.75rem) {
  .slider_second .slide.next,
  .slider_third .slide.next,
  .slider .slide.next {
    -webkit-transform: translateX(70%);
            transform: translateX(70%);
  }
}
@media screen and (min-width: 118.75rem) {
  .slider_second .slide.next,
  .slider_third .slide.next,
  .slider .slide.next {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
}
@media screen and (max-width: 47.4375rem) {
  .slider_second .slide.active,
  .slider_third .slide.active,
  .slider .slide.active {
    display: block;
  }
}
@media screen and (min-width: 61.875rem) {
  .slider_second .slider_navigation,
  .slider_third .slider_navigation,
  .slider .slider_navigation {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    position: absolute;
    top: 0;
    z-index: 999;
  }
}
@media screen and (min-width: 61.875rem) {
  .slider_second .slider_navigation.nav_prev,
  .slider_third .slider_navigation.nav_prev,
  .slider .slider_navigation.nav_prev {
    left: 4%;
  }
}
@media screen and (min-width: 75rem) {
  .slider_second .slider_navigation.nav_prev,
  .slider_third .slider_navigation.nav_prev,
  .slider .slider_navigation.nav_prev {
    left: 10%;
  }
}
@media screen and (min-width: 93.75rem) {
  .slider_second .slider_navigation.nav_prev,
  .slider_third .slider_navigation.nav_prev,
  .slider .slider_navigation.nav_prev {
    left: 15%;
  }
}
@media screen and (min-width: 118.75rem) {
  .slider_second .slider_navigation.nav_prev,
  .slider_third .slider_navigation.nav_prev,
  .slider .slider_navigation.nav_prev {
    left: 24%;
  }
}
@media screen and (min-width: 61.875rem) {
  .slider_second .slider_navigation.nav_next,
  .slider_third .slider_navigation.nav_next,
  .slider .slider_navigation.nav_next {
    right: 4%;
  }
}
@media screen and (min-width: 75rem) {
  .slider_second .slider_navigation.nav_next,
  .slider_third .slider_navigation.nav_next,
  .slider .slider_navigation.nav_next {
    right: 10%;
  }
}
@media screen and (min-width: 93.75rem) {
  .slider_second .slider_navigation.nav_next,
  .slider_third .slider_navigation.nav_next,
  .slider .slider_navigation.nav_next {
    right: 15%;
  }
}
@media screen and (min-width: 118.75rem) {
  .slider_second .slider_navigation.nav_next,
  .slider_third .slider_navigation.nav_next,
  .slider .slider_navigation.nav_next {
    right: 24%;
  }
}
@media screen and (min-width: 30rem) {
  .slider_fade {
    height: 17.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .slider_fade {
    height: 25.625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .slider_fade {
    height: 31.875rem;
  }
}
@media screen and (min-width: 35rem) {
  .slider_fade .slide {
    padding: 0 3.4375rem;
  }
}
@media screen and (max-width: 47.4375rem) {
  .slider_fade .slide.active {
    display: block;
  }
}
@media screen and (min-width: 61.875rem) {
  .slider_fade .slider_navigation {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    position: absolute;
    top: 0;
    z-index: 999;
  }
}
@media screen and (min-width: 61.875rem) {
  .slider_fade .slider_navigation.nav_prev {
    left: 4%;
  }
}
@media screen and (min-width: 75rem) {
  .slider_fade .slider_navigation.nav_prev {
    left: 10%;
  }
}
@media screen and (min-width: 93.75rem) {
  .slider_fade .slider_navigation.nav_prev {
    left: 15%;
  }
}
@media screen and (min-width: 118.75rem) {
  .slider_fade .slider_navigation.nav_prev {
    left: 24%;
  }
}
@media screen and (min-width: 61.875rem) {
  .slider_fade .slider_navigation.nav_next {
    right: 4%;
  }
}
@media screen and (min-width: 75rem) {
  .slider_fade .slider_navigation.nav_next {
    right: 10%;
  }
}
@media screen and (min-width: 93.75rem) {
  .slider_fade .slider_navigation.nav_next {
    right: 15%;
  }
}
@media screen and (min-width: 118.75rem) {
  .slider_fade .slider_navigation.nav_next {
    right: 24%;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_links {
    padding-top: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_overview {
    padding-top: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_share {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-align: start;
            align-items: flex-start;
    margin-top: 2.4em;
    position: relative;
    z-index: 100;
  }
  .social_media_share.fixed {
    position: fixed;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_share ul {
    margin-right: 0.6em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_share li {
    margin: 0.3em 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_share .share_info {
    -webkit-writing-mode: vertical-lr;
        -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: relative;
    text-align: left;
  }
  .social_media_share .share_info::after {
    background: #9d9d9c;
    width: 0.0625rem;
    bottom: 100%;
    margin-bottom: 0.6em;
    content: "";
    right: 90%;
    position: absolute;
    z-index: -1;
    height: 70%;
  }
}
@media screen and (min-width: 47.5rem) {
  .steuernews_preview .news_wrapper {
    padding-top: 3.75rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_preview .news_wrapper {
    border-left: 0;
    padding-top: 5.625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_preview .image {
    padding-left: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_preview .image img {
    width: 85%;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_preview .teaser_text {
    border-left: 0.0625rem solid #000000;
    margin-top: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .news_entity .text_wrapper {
    padding: 6.25rem 0 3.75rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .news_entity .text_wrapper {
    padding: 9.1875rem 0 5.625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .news_entity .news_text {
    padding: 3.75rem 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .news_entity .news_text {
    padding: 5.625rem 0;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview .entry .title {
    font-size: calc(1.25rem + 1.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry .title {
    font-size: 2.625rem;
  }
}
@media screen and (min-width: 35rem) {
  .team_overview .entry .photo,
  .team_overview .entry .bg_photo {
    padding-bottom: 120%;
  }
}
@media screen and (min-width: 47.5rem) {
  .team_overview .entry .photo,
  .team_overview .entry .bg_photo {
    padding-bottom: 100%;
  }
}
@media screen and (min-width: 35rem) {
  .team_overview .entry .photo img,
  .team_overview .entry .bg_photo img {
    max-width: 120%;
    width: 120%;
  }
}
@media screen and (min-width: 47.5rem) {
  .team_overview .entry .photo img,
  .team_overview .entry .bg_photo img {
    max-width: 100%;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview .entry .info {
    font-size: calc(0.875rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry .info {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 125rem) {
  .team_overview .entry .info {
    top: 80%;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview .entry .description {
    font-size: calc(1.125rem + 0.1875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry .description {
    font-size: 1.3125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .team_overview .entry .description ul {
    line-height: 0.8;
  }
}
@media screen and (min-width: 75rem) {
  .team_overview .entry .description ul {
    line-height: 1;
  }
}
@media screen and (min-width: 20rem) {
  .team_entry .font_family_senior {
    font-size: calc(1.75rem + 2
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_entry .font_family_senior {
    font-size: 3.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .team_entry .name {
    font-size: calc(0.9375rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_entry .name {
    font-size: 1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .team_entry .name .first_name {
    font-size: calc(1rem + 1.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_entry .name .first_name {
    font-size: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .team_entry .black_profile {
    font-size: calc(1rem + 0.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_entry .black_profile {
    font-size: 1.375rem;
  }
}
@media screen and (xlarge-width: 30rem) {
  .team_entry .black_profile {
    padding: 6.875rem 6.875rem 6.25rem 0;
  }
}
@media screen and (min-width: 20rem) {
  .team_entry .interview {
    margin-right: calc(1.875rem + 9.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_entry .interview {
    margin-right: 11.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .team_entry .interview h2 {
    margin-top: calc(0.9375rem + 3.4375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_entry .interview h2 {
    margin-top: 4.375rem;
  }
}
@media screen and (max-width: 61.8125rem) {
  .team_entry .photo_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
}
@media screen and (min-width: 61.875rem) {
  .team_entry .photo_wrapper .photo {
    position: absolute;
    padding-bottom: 100vh !important;
  }
  .team_entry .photo_wrapper .photo.fixed {
    position: fixed;
  }
}
@media screen and (min-width: 20rem) {
  .team_entry h2 strong {
    font-size: calc(0.9375rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_entry h2 strong {
    font-size: 1.1875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .teaser_arrow_animation {
    padding: 6.5625rem 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .blog_page .teaser_arrow_animation {
    padding: 6.5625rem 0 6.5625rem 2.5rem;
  }
  .blog_page .teaser_arrow_animation:before {
    content: none;
  }
}
@media screen and (min-width: 75rem) {
  .teaser_arrow_animation .description {
    max-width: 50%;
  }
}
@media screen and (min-width: 75rem) {
  .teaser_arrow_animation .teaser_image {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    margin-right: 50vw;
    padding-left: 50vw;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: margin-right 1500ms;
    transition: margin-right 1500ms;
    max-width: 100%;
  }
  .blog_page .teaser_arrow_animation .teaser_image {
    margin-right: 35vw;
  }
}
@media screen and (min-width: 75rem) {
  .teaser_arrow_animation .teaser_image img {
    height: 100%;
    width: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
  }
}
@media screen and (max-width: 61.8125rem) {
  .text_image_teaser .image {
    max-width: 18.75rem;
  }
}
@media screen and (max-width: 34.9375rem) {
  .text_image_teaser .image {
    max-width: 12.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .text_image_teaser p {
    max-width: 60vw;
  }
}
@media screen and (min-width: 20rem) {
  .triple_text_overview .triple_text .description {
    font-size: calc(0.9375rem + 2.0625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .triple_text_overview .triple_text .description {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .triple_text_overview.leistungen .title {
    font-size: calc(1.25rem + 1.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .triple_text_overview.leistungen .title {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .triple_text_overview.leistungen .description {
    font-size: calc(0.9375rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .triple_text_overview.leistungen .description {
    font-size: 1.1875rem;
  }
}
@media screen and (min-width: 20rem) {
  .triple_text_overview.air .title,
  .triple_text_overview.mta .title,
  .triple_text_overview.ekon .title,
  .triple_text_overview.mykron .title,
  .triple_text_overview.plenon .title,
  .triple_text_overview.zeron .title {
    font-size: calc(1.25rem + 1.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .triple_text_overview.air .title,
  .triple_text_overview.mta .title,
  .triple_text_overview.ekon .title,
  .triple_text_overview.mykron .title,
  .triple_text_overview.plenon .title,
  .triple_text_overview.zeron .title {
    font-size: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .triple_text_overview.air .description,
  .triple_text_overview.mta .description,
  .triple_text_overview.ekon .description,
  .triple_text_overview.mykron .description,
  .triple_text_overview.plenon .description,
  .triple_text_overview.zeron .description {
    font-size: calc(0.9375rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .triple_text_overview.air .description,
  .triple_text_overview.mta .description,
  .triple_text_overview.ekon .description,
  .triple_text_overview.mykron .description,
  .triple_text_overview.plenon .description,
  .triple_text_overview.zeron .description {
    font-size: 1.1875rem;
  }
}
@media screen and (min-width: 20rem) {
  .your_entry_jobs_teaser_overview {
    margin-bottom: calc(1.2em + 17.55
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .your_entry_jobs_teaser_overview {
    margin-bottom: 18.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .your_entry_jobs_teaser_overview {
    margin-top: calc(1.2em + 5.05
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .your_entry_jobs_teaser_overview {
    margin-top: 6.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser {
    min-height: calc(15.625rem + 24.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser {
    min-height: 40rem;
  }
}
@media screen and (min-width: 20rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser {
    padding-bottom: calc(1.2em + 2.3999999999999995
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser {
    padding-bottom: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser {
    padding-left: calc(1.2em + 2.3999999999999995
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser {
    padding-left: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser {
    padding-right: calc(1.7999999999999998em + 6.6000000000000005
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser {
    padding-right: 8.4em;
  }
}
@media screen and (min-width: 20rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser {
    padding-top: calc(1.2em + 2.3999999999999995
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser {
    padding-top: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser .title {
    font-size: calc(1.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser .title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser .title_addition {
    font-size: calc(1.875rem + 1.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser .title_addition {
    font-size: 3.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser a .text {
    font-size: calc(1rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .your_entry_jobs_teaser_overview .your_entry_jobs_teaser a .text {
    font-size: 1.625rem;
  }
}
@media print {
  .company_logo,
  .company_logo_addition {
    display: block;
    margin-bottom: 0.6em;
    max-width: 11.25rem !important;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  h2 {
    font-size: 1.375rem;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.2em;
    page-break-inside: avoid;
  }
  .section {
    margin: 0 !important;
    padding: 0 !important;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 600;
    margin-bottom: 0.6em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.2em;
  }
  .company_logo {
    display: block;
    max-width: 18.75rem;
  }
  .company_logo img {
    display: block;
  }
  .jobs_and_career_sitetitle {
    margin-top: 0 !important;
  }
  .yellow_text_area {
    margin: 3.125rem 0 !important;
  }
  .team_overview .entry {
    margin-bottom: 3.125rem !important;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.2em 0 0;
    padding: 0.6em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
